import { EntityMetadata } from './document';

export class Folder {
  id: string;
  folderName: string;
  folderAlias?: string;
  footerContent?: string = '';
  createdBy: string;
  createdOn: number;
  subFolders: any = [];
  metaData?: EntityMetadata;
  parentFolders?: any[] = [];
  parentFolderId? = '';
  parentFolderName? = '';


  constructor(folder) {
    this.id = folder.id;
    this.folderName = folder.folderName;
    this.folderAlias = folder.folderAlias;
    this.footerContent = folder.footerContent;
    this.createdBy = folder.createdBy;
    this.createdOn = folder.createdOn;
    let subfolders: Folder[] = [];
    this.parentFolders = folder.parentFolders;

    // if the subFolders is a string, then JSON parse is needed. If not, then use the value as is.
    // This is needed to avoid parse error when parsing subFolders recurssively
    if (typeof folder.subFolders === 'string') {
      subfolders = JSON.parse(folder.subFolders);
    } else if (folder.subFolders) {
      subfolders = folder.subFolders;
    }

    this.subFolders = subfolders?.map(subFolder => new Folder(subFolder));
    this.subFolders = this.subFolders.sort((f1, f2) => {
      if (f1.folderName > f2.folderName) {
        return 1;
      }
      if (f1.folderName < f2.folderName) {
        return -1;
      }

      return 0;
    });

    if (typeof folder.parentFolders === 'string') {
      this.parentFolders = JSON.parse(folder.parentFolders);
    } else if (folder.parentFolders) {
      this.parentFolders = folder.parentFolders;
    }

    if (this.parentFolders?.length) {
      this.parentFolderId = this.parentFolders[this.parentFolders.length - 1].id;
      this.parentFolderName = this.parentFolders[this.parentFolders.length - 1].folderName;
    }
    this.metaData = new EntityMetadata(folder);

  }
}
