import { FormFieldStore } from 'form/formFieldStore';
import { action } from 'mobx';
import { FieldType } from 'api/fieldModel';
import { FieldValidator } from './fieldValidator';

const TEXT_MAX = 1750;

export class LengthValidator implements FieldValidator {
  textSize: number;
  constructor(textSize: number = TEXT_MAX) {
    this.textSize = textSize;
  }
  validate = (formFieldStore: FormFieldStore) => {
    const store = formFieldStore;
    const { field: { value, type } } = store;
    if ((type === FieldType.TEXT || type === FieldType.TEXTAREA) && value && value.length > this.textSize) {
      return false;
    }
    if (type === FieldType.MULTISELECT && Array.isArray(value) && value.length < 1) {
      return false;
    }

    if (type === FieldType.SELECT && value && value.label.length < 1) {
      return false;
    }
    return true;
  };

  @action
  getErrorText = (formFieldStore: FormFieldStore) => {
    const store = formFieldStore;
    const { field: { value, type } } = store;
    if ((type === FieldType.TEXT || type === FieldType.TEXTAREA)) {
      return `This field has a maximum length of ${this.textSize} characters. Current length: ${value.length}`;
    } else {
      return 'Field is required';
    }
  };
}
