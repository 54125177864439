import { ModalStore } from 'components/modal/modalStore';
import AppStore, { AccessType } from 'container/app/appStore';
import { FolderFieldID } from '../dbFolderContainer';
import { action, observable } from 'mobx';
import { SelectFolderStore } from 'fields/selectField/selectFolderStore';
import { FieldGroupModel, FieldType } from 'api/fieldModel';

export class AddFolderModalStore extends ModalStore {
  @observable updatedRootFolder;
  @observable folderAlias;
  @observable footerContent;

  // eslint-disable-next-line no-useless-constructor
  constructor(header: string, appStore: AppStore) {
    super(header, appStore);
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  listenFieldChange(fieldID: string, value: any) {
    const clonePermissionField = this.formFieldsById.get(FolderFieldID.CLONE_PERMISSION)?.field;
    const parentFolderId = this.formFieldsById.get(FolderFieldID.PARENT_ID)?.field;
    if (fieldID !== FolderFieldID.PARENT_ID) {
      return;
    }

    if (clonePermissionField) {
      if (parentFolderId?.value) {
        clonePermissionField.name = 'Clone permissions from parent folder';
        clonePermissionField.disabled = false;
        clonePermissionField.required = false;
        clonePermissionField.hidden = false;
        clonePermissionField.value = true;
      } else {
        clonePermissionField.name = 'Clone permissions from parent folder';
        clonePermissionField.disabled = true;
        clonePermissionField.required = false;
        clonePermissionField.value = false;
        clonePermissionField.hidden = false;
      }
    }
  }

  @action
  updateForm() {
    const clonePermissionField = this.formFieldsById.get(FolderFieldID.CLONE_PERMISSION)?.field;
    const parentFolderId = this.formFieldsById.get(FolderFieldID.PARENT_ID)?.field;
    if (clonePermissionField) {
      if (parentFolderId?.value) {
        clonePermissionField.disabled = false;
        clonePermissionField.name = 'Clone permissions from parent folder';
        clonePermissionField.value = true;
        clonePermissionField.required = false;
        clonePermissionField.hidden = false;
      }
    }
  }

  initFields(): FieldGroupModel[] {
    const nameField = {
      id: FolderFieldID.NAME,
      name: 'Name',
      description: '',
      type: FieldType.TEXT,
      required: true,
      value: null,
    };
    const treeField = {
      id: FolderFieldID.PARENT_ID,
      name: 'Select parent folder',
      description: '',
      type: FieldType.SELECT,
      placeholder: 'Choose a folder ',
      required: true,
      value: this.appStore.selectedFolder ? { value: this.appStore.selectedFolder.id, label: this.appStore.selectedFolder.folderName } : null,
      customFieldStore: new SelectFolderStore(this.appStore, AccessType.WRITE),
    };
    const checkboxField = {
      id: FolderFieldID.CLONE_PERMISSION,
      name: 'Clone permissions from parent folder',
      label: '',
      description: '',
      type: FieldType.CHECKBOX,
      required: false,
      value: false,
      hidden: false,
      disabled: true,
    };

    /* Enables public folder and document feature. */
    const isPublicField = {
      id: FolderFieldID.IS_PUBLIC,
      name: 'Make folder public and allow everyone to view',
      description: 'Please ensure the parent folder is public and you have admin access',
      type: FieldType.CHECKBOX,
      required: true,
      value: false,
    };

    const folderAlias = {
      id: FolderFieldID.FOLDER_ALIAS,
      name: 'Pretty URL',
      description: 'This is the pretty url for the org',
      type: FieldType.TEXT,
      placeholder: '',
      value: this.folderAlias,
    };

    const folderDescription = {
      id: FolderFieldID.LANDING_PAGE_DESCRIPTION,
      name: 'Landing Page Description',
      description: 'This description will be displayed on the org page',
      type: FieldType.TEXTAREA,
      placeholder: 'Please type landing page description',
      value: this.footerContent,
    };

    return [{
      id: 'default_',
      name: 'default_',
      fields: [
        nameField,
        treeField,
        checkboxField,
        folderAlias,
        folderDescription,
      ],
    }] as FieldGroupModel[];
  }
}

