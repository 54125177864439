export const EXTERNAL_LINKS = {
  FOLDER_MANAGEMENT_HELP: 'https://policy.a2z.com/docs/501045/publication',
  PERMISSION_MANAGEMENT_HELP: 'https://policy.a2z.com/docs/501061/publication',
  DOCUMENT_MANAGEMENT_HELP: 'https://policy.a2z.com/docs/501049/publication',
  EXCEPTION_MANAGEMENT_HELP: 'https://policy.a2z.com/docs/592198/publication',
  PROCESS_MANAGEMENT_HELP: 'https://policy.a2z.com/docs/501221/publication',
  CREATE_ISSUE: 'https://issues.amazon.com/issues/create?template=7237de9e-1b31-4b64-8c96-62be3db8192a',
  USER_GUIDE: 'https://policy.a2z.com/#folderId=24c12d49-7a52-b779-f8d4-444750b807fd/4ac12d47-cf19-82e6-b69e-02ec8be3c561/',
  SCHEDULED_REVIEWS: 'https://policy.a2z.com/docs/594305/publication',
};
