import { Popover as MaterialPopover } from '@material-ui/core';
import { Button, Icon, IconProps, StatusIndicator } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import './sectionLinkCopyIconStyles.scss';

interface CopyIconProps {
  copyContent?: string;
  message?: string;
  icon?: IconProps.Name;
  type?: 'Button' | 'Icon';
  folderAlias?: string;
}

interface PopoverContentProps {
  success: boolean;
  message?: string;
}

const PopoverContent = (props: PopoverContentProps): JSX.Element => {
  const { success, message = 'Copied!' } = props;
  const msg = success ? message : 'Failed to copy. Try again later.';
  const statusType = success ? 'success' : 'error';
  return (
    <StatusIndicator type={statusType}>
      {msg}
    </StatusIndicator>
  );
};

const CopyButton = (props: CopyIconProps): JSX.Element => {
  const { copyContent, icon = 'copy', message, type = 'Button', folderAlias = '' } = props;
  const [anchor, setAnchor] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const getUrl = (): string => {
    if (copyContent) {
      const { origin, pathname, search } = window.location;
      return `${origin}${pathname}${search}#${copyContent}`;
    } else if (folderAlias.length > 0) {
      return `${origin}/org/${folderAlias}`;
    } else if (window.location.href.includes('#folderId=')) { // On landing page, extract folderPath and generate org view URL
      const [, folderPath] = window.location.href.split('#folderId=');
      const { origin } = window.location;
      return `${origin}/folder/${folderPath}`;
    } else {
      return window.location.href;
    }
  };

  const handleClick = (event: any) => {
    try {
      navigator.clipboard.writeText(getUrl());
      setCopySuccess(true);
    } catch (e) {
      setCopySuccess(false);
    }

    setAnchor(event.currentTarget); // set anchor to function caller
    event.stopPropagation(); // prevent toggle expandable
    setTimeout(handleClose, 1000); // auto close for user
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(null);
    event?.stopPropagation();
  };

  const open = Boolean(anchor);

  return (
    <div style={{ cursor: 'pointer' }}>
      <div id="popover-icon" onClick={handleClick} title="Copy folder URL">
        {type === 'Icon' ?
          <Icon name={icon} variant="subtle" /> :
          <Button iconName="copy">Link</Button>
        }
      </div>

      <MaterialPopover
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '10px' }}>
          <PopoverContent success={copySuccess} message={message} />
        </div>
      </MaterialPopover>
    </div>
  );
};

export default CopyButton;
