import React from 'react';
export interface ErrorModel {
  statusCode: string;
  message: string | React.ReactNode;
  errorInfo?: string;
}

export function isErrorModel(o: any): o is ErrorModel {
  return 'statusCode' in o && 'message' in o;
}
