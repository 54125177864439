import * as React from 'react';
import { Spinner } from '@amzn/awsui-components-react';


interface State {
  error: string;
}

export class ErrorBoundary extends React.PureComponent<unknown, State> {
  constructor(props: State) {
    super(props);
    this.state = { error: '' };
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static getDerivedStateFromError(error: Error): object {
    return { error: error.message };
  }

  render(): any {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <Spinner size="large" />
      );
    }

    return children;
  }
}
