import { deleteFavorites, updateFavorites } from 'api/graphql/mutation';
import { getFavorites } from 'api/graphql/query';
import AppStore from 'container/app/appStore';
import { action, observable } from 'mobx';

export type AllFavoriteMetadata = FavoriteMetadata[];

export interface FavoriteMetadata {
  entityId: string,
  entityType: string,
  id?: string,
  title: string,
  description?: string,
}

export class FavoritesStore {
  @observable appStore: AppStore;
  @observable allFavoriteMetadata: AllFavoriteMetadata = [];
  @observable entity: any;
  @observable entityId: string;
  @observable entityType: 'document' | 'folder';

  constructor(appStore: AppStore, entityId: string, entityType: 'document' | 'folder', entity?: any) {
    this.appStore = appStore;
    this.entityId = entityId;
    this.entity = entity;
    this.entityType = entityType;
  }

  /**
   * Determins which API call should be made depending on the current favorited status.
   * @param {boolean} isfavorited Current favorited status.
   * @returns {Promise<AllFavoriteMetadata>} Updated value for AllFavoriteMetadata.
   */
  @action
  onFavoriteClick(isfavorited: boolean): Promise<AllFavoriteMetadata> {
    return isfavorited ? this.updateFavorites() : this.deleteFavorites();
  }

  /**
   * Gets initial data on what has already been favorited.
   * @returns {Promise<any>} Promise from API call.
   */
  @action
  getFavorites(): Promise<any> {
    return this.appStore.api.query(getFavorites, {})
      .then(action(({ data }) => {
        const favorites = JSON.parse(data.getFavorites);
        this.allFavoriteMetadata = favorites;
        return favorites;
      }))
      .catch(() => {
        this.createToastAlert('Failed to get favorites. Please try again later.');
      });
  }

  /**
   * Adds entity to list of favorites.
   * @returns {Promise<AllFavoriteMetadata>} Promise from API call.
   */
  @action
  updateFavorites(): Promise<AllFavoriteMetadata> {
    const params = {
      entityId: this.entityId,
      entityType: this.entityType,
    };

    return this.appStore.api.mutate(updateFavorites, params)
      .then(action(() => {
        const data = [...this.allFavoriteMetadata, { title: this.entity?.folderName, ...params, ...this.entity }];
        this.allFavoriteMetadata = data;
        this.createToastAlert('Successfully added to favorites!', 'success', '200');
        return data;
      }))
      .catch(() => {
        this.createToastAlert('Failed to add to favorites. Please try again later.');
        return this.allFavoriteMetadata;
      });
  }

  /**
   * Removes entity from list of favorites.
   * @returns {Promise<AllFavoriteMetadata>} Promise from API call.
   */
  @action
  deleteFavorites(): Promise<AllFavoriteMetadata> {
    const params = {
      entityId: this.entityId,
      entityType: this.entityType,
    };

    return this.appStore.api.mutate(deleteFavorites, params)
      .then(action(() => {
        const data = this.allFavoriteMetadata.filter(metaData => metaData.entityId !== this.entityId);
        this.allFavoriteMetadata = data;
        this.createToastAlert('Successfully removed from favorites.', 'success', '200');
        return data;
      }))
      .catch(() => {
        this.createToastAlert('Failed to remove from favorites. Please try again later.');
        return this.allFavoriteMetadata;
      });
  }

  /**
   * Generates an alert for the user.
   * @param {string} message Message displayed in the alert.
   * @param {string} status Alert type
   * @param {string} statusCode Alert code.
   */
  createToastAlert(message: string, status = 'error', statusCode = '400'): void {
    this.appStore.appToastAlertStore.addToastAlert({
      message,
      statusCode,
    }, status);
  }
}
