/**
 * Monitor real-time client-side data through CloudWatch RUM.
 *
 * References:
 * https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM.html
 * https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
 */

import { AwsRum, AwsRumConfig } from 'aws-rum-web';

enum Env {
  Prod = 'Prod',
  Gamma = 'Gamma',
  Alpha = 'Alpha',
}

enum GuestRoleArn {
  Prod = 'arn:aws:iam::156888897010:role/RUM-Monitor-us-east-1-156888897010-2431362666761-Unauth',
  Gamma = 'arn:aws:iam::859827997151:role/RUM-Monitor-us-east-1-859827997151-2435311646961-Unauth',
  Alpha = 'arn:aws:iam::948710311118:role/RUM-Monitor-us-east-1-948710311118-2661499546961-Unauth',
}

enum IdentityPoolId {
  Prod = 'us-east-1:5c954bce-4bfe-4038-815a-729e940deba1',
  Gamma = 'us-east-1:7f8fef60-e16a-43f3-9566-925da15c5ff0',
  Alpha = 'us-east-1:c9408cee-07ec-42d7-a092-e79f9f0145a3',
}

enum ApplicationId {
  Prod = '936c9a4f-7bc9-431a-a7df-81edd99b8358',
  Gamma = '9a7f64c8-1c80-43b2-bc93-593688dc3b88',
  Alpha = 'c4b61834-d178-438d-9687-36e4b84bf572',
}

const configureCloudWatchRUM = (hostname: string = window?.location?.hostname): string => {

  try {
    let environment;

    switch (hostname) {
      case 'policy.a2z.com':
        environment = Env.Prod;
        break;
      case 'gamma.policy.a2z.com':
        environment = Env.Gamma;
        break;
      case 'alpha.policy.a2z.com':
        environment = Env.Alpha;
        break;
      case 'localhost':
        environment = Env.Alpha;
        break;
      default: environment = null;
    }

    if (environment) {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn: GuestRoleArn[environment],
        identityPoolId: IdentityPoolId[environment],
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        telemetries: ['performance', 'errors', 'http'],
        allowCookies: true,
        enableXRay: true,
        disableAutoPageView: true, // Pages should be manually recorded below.
      };

      const APPLICATION_ID = ApplicationId[environment];
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'us-east-1';

      const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );

      /**
       * Manually record pages to group pages that use an ID in the URL path.
       * Eg: Treat "/docs/101" and "/docs/102" as members of the same group.
       *
       * References:
       * https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-modify-snippet.html
       * https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch-RUM-page-groups.html
       */
      const location = window.location.pathname.split('/');
      let pageId = location?.[1] ? location[1] : 'home';
      const queryParamsIndex = pageId ? pageId.indexOf('?') : -1;

      // Remove any query params.
      if (pageId && queryParamsIndex > -1) pageId = pageId.substring(0, queryParamsIndex);
      awsRum.recordPageView({ pageId });
    }

    return `SUCCESS - ${environment}`;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    return 'ERROR';
  }
};

export default configureCloudWatchRUM;
