

export enum TaskType {
  migration = 'migration',
  exceptions = 'exceptions'
}

export enum MigrationStatus {
  approved = 'Approved',
  rejected = 'Rejected',
  none = 'Pending'
}

export interface TaskboardItem {
  entityId: string,
  createdBy: string,
  title: string,
}

export interface MigrationTaskboardItem extends TaskboardItem {
  rejectionReason: string,
  status: MigrationStatus,
  parentFolder?: string,
  grcId: string, // Incoming value can be null, but required in appsync, hence this req'd field
}

export type URLResolver = () => Promise<URL | undefined>;
export interface ExceptionTaskboardItem extends TaskboardItem {
  status: any;
  policyURLResolver: URLResolver;
  isExceptionDraft: boolean;
  isExceptionRequest: boolean;
  approvalStatus: 'draft' | 'review' | 'publication';
  targetDocId: string;
  createdOn: string;
  expiration?: number;
  exceptionState?: string;
  version?: string;
  primaryOwner? : string;
  secondaryOwner?: string;
  managerAlias?: string;
  department?: string;
  seniorTeam?: string;
  l8Leadership?:string;
  lastUpdateDate?:string;
  tags?:string;
  exceptionTags?:string;
  lastModifiedOn?:string;
}
