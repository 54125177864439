import { Button, Container, Grid, Header, Icon, SpaceBetween, TextContent } from '@amzn/awsui-components-react/polaris';
import Tree from 'components/tree/tree';
import React from 'react';
import './dbFolderContainerStyles.scss';
import FolderIcon from '@material-ui/icons/Folder';
import { action, observable } from 'mobx';
import AppStore from 'container/app/appStore';
import { DbFolderContainerStore } from './dbFolderContainerStore';
import { v4 as UUID } from 'uuid';
import { observer } from 'mobx-react';
import { DocumentTile } from './documentTile/documentTile';
import StarIcon from '@mui/icons-material/Star';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';

interface Props {
  appStore?: AppStore;
  match: any;
}

export enum FolderFieldID {
  NAME = 'folderName',
  PARENT_ID = 'parentId',
  ROLES = 'roles',
  ASSIGN_USER = 'assign',
  CLONE_PERMISSION = 'clonePermission',
  IS_PUBLIC = 'isPublic',
  IS_RELATED = 'isRelated',
  LANDING_PAGE_DESCRIPTION = 'footerContent',
  FOLDER_ALIAS = 'folderAlias'
}

@observer
export class DBFolderContainer extends React.PureComponent<Props, unknown> {
  @observable key;

  constructor(props) {
    super(props);
    this.createStore();
  }

  componentDidMount() {
    this.store.load();
  }

  get store() {
    const { appStore } = this.props;
    return appStore!.getStore(this.key || '') as DbFolderContainerStore;
  }

  @action
  createStore() {
    const { appStore, match } = this.props;
    this.key = appStore!.createCacheKey(UUID(), match);
    appStore!.createStore(this.key, DbFolderContainerStore);
  }

  renderHeader(): any {
    const { appStore } = this.props;
    const isFavoritesFolder = this.store.selectedFolder?.id === 'FAVORITES';
    const showFavorites = appStore?.favoritesFeatureEnabled && this.store.favoritesStore && this.store.allFavoriteMetadata?.length > 0;

    return (
      <section style={{ paddingBottom: '0.5rem' }}>
        <Header
          variant="h2"
          actions={
            <section id="favorites-section">
              <Button className={showFavorites ? '' : 'hide'} disabled={!showFavorites} variant="link" onClick={() => this.store.showFavorites()}>
                <SpaceBetween direction="horizontal" size="xxxs">
                  <div id="favorites-icon-container">
                    <StarIcon id="favorites-icon" className={isFavoritesFolder ? 'favorited' : 'not-favorited'} />
                  </div>
                  <div id="favorites-title" className={isFavoritesFolder ? 'favorited' : 'not-favorited'}>
                    Show Favorites
                  </div>
                </SpaceBetween>
              </Button>
            </section>
          }
        >
          <span className="policy--box-header">Folders ({this.store.folders.length})</span>
        </Header>
      </section>
    );
  }

  renderFileDetails() {
    const { appStore } = this.props;
    return (
      <DocumentTile
        appStore={appStore}
        documentWrapper={this.store?.documentWrapper}
        dbFolderContainerStore={this.store}
        favoritesStore={this.store.favoritesStore}
        isLoading={this.store.loadingDocuments}
      />
    );
  }

  renderNoFolders(): any {
    return (
      <TextContent>
        <section id="policy--no-folders-section">
          <span className="policy--document-icon-warning justify-center">
            <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
            <Icon name="folder-open" size="big" />
            <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
          </span>
          <h3 className="justify-center">No Policies Available</h3>
          <p className="justify-center">There is no information to display here because you do not have permission to any policy folders.</p>
          <p className="justify-center">Select, &quot;+ New&quot;, to get started, or ask your manager to grant permission to your org&apos;s folders.</p>
        </section>
      </TextContent>
    );
  }

  render(): any {
    const { treeViewDefaults, handleFolderSelected, loading, folders } = this.store;

    return (
      <Container className="policy--dashboard-toplevel-layout">
        {loading ? <LoadingSpinner size="large" absoluteCenter /> : (
          <section>
            {!folders.length ? this.renderNoFolders() : (
              <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                <div className="policy--dashboard-box-layout policy--dashboard-tree-color">
                  <section id="policy--folder-tree-section">
                    <Tree
                      nodes={folders}
                      loading={loading}
                      header={this.renderHeader()}
                      allowStaticSearch
                      labelIcon={FolderIcon}
                      nameField={'folderName'}
                      childField={'subFolders'}
                      classNames={'folderName'}
                      onSelected={handleFolderSelected}
                      treeViewDefaults={treeViewDefaults}
                      defaultSelected={treeViewDefaults.defaultSelected}
                      storeItem={this.store}
                    />
                  </section>
                </div>
                <div className="policy--dashboard-box-layout">{this.renderFileDetails()}</div>
              </Grid>
            )}
          </section>
        )}
      </Container>
    );
  }
}
