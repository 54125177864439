import { ModalStore } from 'components/modal/modalStore';
import AppStore from 'container/app/appStore';
import { observable } from 'mobx';
import { FieldGroupModel, FieldType } from 'api/fieldModel';


export enum PreferenceFieldId {
    MY_ORANIZATION = 'myOrganization',
}

export class PreferenceModalStore extends ModalStore {
  @observable updatedRootFolder;
  @observable organizationUrl;
  @observable footerContent;

  // eslint-disable-next-line no-useless-constructor
  constructor(appStore: AppStore, organizationUrl: string) {
    super('Preference', appStore);
    this.organizationUrl = organizationUrl;
  }

  initFields(): FieldGroupModel[] {
    const myOrgField = {
      id: 'organizationUrl',
      name: 'My Organization',
      description: '',
      type: FieldType.TEXT,
      required: true,
      value: this.organizationUrl,
    };

    return [{
      id: 'default_',
      name: 'default_',
      fields: [
        myOrgField,
      ],
    }] as FieldGroupModel[];
  }
}

