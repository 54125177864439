import { Folder } from 'api/folder';
import { getFolders } from 'api/graphql/query';
import { ErrorModel } from 'components/error/errorModel';
import AppStore, { AccessType } from 'container/app/appStore';
import { FormFieldStore } from 'form/formFieldStore';
import { action, observable } from 'mobx';
import { SearchSelectOptionsStore } from './searchSelectOptionsStore';
import { SelectOption } from './selectModel';
import { cloneDeep } from 'lodash';

export class SelectFolderStore implements SearchSelectOptionsStore {
  @observable formFieldStore!: FormFieldStore;
  @observable appStore: AppStore;
  @observable folderOptions?: SelectOption[];
  @observable accessType: string;
  @observable excludeFloder: string[] = [];
  @observable updateTimeout: ReturnType<typeof setTimeout> | null = null;


  constructor(appStore: AppStore, accessType = AccessType.READ, excludeFloder: string[] = []) {
    this.appStore = appStore;
    this.accessType = accessType;
    this.excludeFloder = excludeFloder;
  }

  onSearch(input: string, access: string): void {
    if (this.updateTimeout) clearTimeout(this.updateTimeout);
    if (!input.length) return;
    if (!this.accessType) {
      this.accessType = access;
    }
    this.appStore.api.query(getFolders, { loadAll: false, accessTypes: this.accessType, folderName: input })
      .then(value => {
        this.updateTimeout = setTimeout(action(() => {
          this.formatSearchedFolders(value);
        }), 750);
      })
      .catch(e => this.onError(e.errors[0], this.formFieldStore));
  }

  getParentFolders(parentFolders) {
    const parentPath = ['Path: '];
    for (const folder of (typeof parentFolders === 'string' ? JSON.parse(parentFolders) : parentFolders)) {
      parentPath.push(folder.folderName);
    }
    const pathString = parentPath.join('/');
    return [pathString];
  }

  @action
  formatSearchedFolders(value) {
    if (!value.data.getFolders) {
      this.formFieldStore.field.options = [];
      return;
    }
    const folders: Folder[] = [];
    for (const item of value.data.getFolders) {
      const folderItem = new Folder(item);
      // Filter to only show folder under org if creating on org view
      let visible = true;
      if (this.appStore.rootFolderId.length > 0) {
        const parentIds = folderItem.parentFolders?.map(f => f.id);
        // Exclude folder if root folder id is not in parent ids and is not current folder id
        if (!parentIds?.includes(this.appStore.rootFolderId) && folderItem.id !== this.appStore.rootFolderId) {
          visible = false;
        }
      }
      if (this.excludeFloder.indexOf(item.id) < 0 && visible) {
        folders.push(folderItem);
      }
    }
    const options: SelectOption[] = [];
    for (const folder of folders) {
      options.push({
        label: folder.folderName,
        value: folder.id,
        tags: this.getParentFolders(folder.parentFolders),
      });
    }
    this.formFieldStore.field.options = options;
  }

  load(formFieldStore: FormFieldStore): Promise<any[]> {
    this.formFieldStore = formFieldStore;
    this.formFieldStore.loading = true;
    let p;
    if (this.appStore.rootFolderId) { // Only load folders under org when creating on org view
      p = this.appStore.api.query(getFolders, { loadAll: false, accessTypes: this.accessType, folderIds: this.appStore.rootFolderId });
    } else {
      p = this.appStore.api.query(getFolders, { loadAll: true, accessTypes: this.accessType });
    }
    p
      .then(value => this.onLoad(value, this.formFieldStore))
      .catch(() => this.appStore.createToastAlert('Failed to get folder data! Please try again later.'))
      .finally(action(() => {
        this.formFieldStore.loading = false;
      }));
    return p;
  }

  @action
  onLoad = (value, formFieldStore: FormFieldStore) => {
    const f = formFieldStore;
    f.loading = false;
    const folders: Folder[] = value.data.getFolders?.map(folder => new Folder(folder)) ?? [];
    this.folderOptions = this.convertFolderToOptions(folders, [], []);
    f.field.options = this.folderOptions;
  };

  convertFolderToOptions(folders, items: SelectOption[], tags: string[]) {
    if (!folders && folders.length === 0) return;
    folders.forEach(folder => {
      // if the folder is not part of exclude list
      if (this.excludeFloder.indexOf(folder.id) < 0) {
        items.push({
          label: folder.folderName,
          value: folder.id,
          tags: ['  ', `Path: ${tags.join(' / ')}`],
        });
        const currentTag = cloneDeep(tags);
        currentTag.push(folder.folderName);
        // this.convertFolderToOptions(folder.subFolders, items, currentTag);
      }
    });
    return items;
  }

  @action
  onError = (err: ErrorModel, formFieldStore: FormFieldStore) => {
    const f = formFieldStore;
    f.errorText = err.message as string;
    f.loading = false;
  };
}
