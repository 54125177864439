import { FormFieldStore } from 'form/formFieldStore';
import { action, observable } from 'mobx';
import _ from 'lodash';
import AppStore from 'container/app/appStore';
import { DocumentSummary } from 'api/document';
import { SearchSelectOptionsStore } from './searchSelectOptionsStore';
import { queryDocuments } from 'api/graphql/mutation';
/* eslint-disable no-underscore-dangle */


export class SelectDocStore implements SearchSelectOptionsStore {
  @observable appStore: AppStore;
  @observable docType?: string;
  @observable isTemplate?= false;
  @observable formFieldStore!: FormFieldStore;
  @observable hasPublication?= true;
  debouncedSearch = _.debounce(this.onSearch.bind(this), 750);

  constructor(appStore: AppStore, docType?: string, isTemplate?: boolean, hasPublication?: boolean) {
    this.appStore = appStore;
    this.docType = docType;
    this.isTemplate = isTemplate;
    this.hasPublication = hasPublication;
  }

  @action
  load(formFieldStore: FormFieldStore): Promise<any[]> {
    this.formFieldStore = formFieldStore;
    this.formFieldStore.loading = true;
    this.formFieldStore.field.options = [];
    this.formFieldStore.errorText = '';
    const params = {
      searchQuery: {
        isTemplate: this.isTemplate,
        instanceType: [this.hasPublication ? 'publication' : 'publication_and_draft'],
        status: ['active'],
      },
    };
    if (this.docType) {
      // eslint-disable-next-line dot-notation
      params.searchQuery['documentType'] = this.docType;
    }
    return this.appStore!.api.mutate(queryDocuments, params)
      .then(returnValue => {

        const documentResponse: any[] = JSON.parse(returnValue.data.queryDocuments).body.documents.map(doc => ({
          id: doc._source.documentId,
          title: doc._source.title,
          description: doc._source.description,
          documentType: doc._source.documentType,
          documentStatus: doc._source.documentStatus,
        }));

        this.onLoad(documentResponse as DocumentSummary[]);
        return [];
      })
      .catch(err => {
        this.onError(err, this.formFieldStore);
        return [];
      });
  }

  @action
  setIsTemplate(isTemplate: boolean) {
    this.isTemplate = isTemplate;
  }

  @action
  setDocType(docType?: string) {
    this.docType = (docType === 'All') ? undefined : docType;
  }

  @action
  onSearch(queryValue: string) {
    if (queryValue === '') return;
    this.formFieldStore.loading = true;
    this.formFieldStore.field.options = [];
    this.formFieldStore.errorText = '';
    const params = {
      searchQuery: {
        title: queryValue,
        isTemplate: this.isTemplate,
        instanceType: [this.hasPublication ? 'publication' : 'publication_and_draft'],
        status: ['active'],
      },
    };
    if (this.docType) {
      // eslint-disable-next-line dot-notation
      params.searchQuery['documentType'] = this.docType;
    }
    const p = this.appStore!.api.mutate(queryDocuments, params);

    p.then(returnValue => {
      const documentResponse: any[] = JSON.parse(returnValue.data.queryDocuments).body.documents.map(doc => ({
        id: doc._source.documentId,
        title: doc._source.title,
        description: doc._source.description,
        documentType: doc._source.documentType,
        documentStatus: doc._source.documentStatus,
      }));
      this.onLoad(documentResponse as DocumentSummary[]);
    })
      .catch(err => this.onError(err, this.formFieldStore));
    return p;
  }

  @action
  onLoad(documentSummaries: DocumentSummary[]) {

    this.formFieldStore.loading = false;
    const options = documentSummaries.map(doc => ({
      label: doc.title,
      value: doc.id,
      labelTag: doc.isTemplate ? `${this.appStore.getDocumentTypeModel(doc.documentType)!.label} Template` : '',
    }));
    this.formFieldStore.field.options = _.sortBy(options, 'label');
  }

  @action
  onError = (err, formFieldStore: FormFieldStore) => {
    const f = formFieldStore;
    try {
      f.errorText = JSON.parse(err.message).message;
    } catch (__) {
      // The above JSON.parse started failing - this line prevents crashing
      f.errorText = 'An error occurred - please try again later';
    }
    f.loading = false;
    f.field.options = [];
  };
}
