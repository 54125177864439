/** ****************************************************************************************
 * Reusable Loading Spinner
 *
 * Uses Polaris Spinner component with added features.
 * Reference: https://polaris.a2z.com/components/awsui-spinner/?tabId=playground
*******************************************************************************************/

import * as React from 'react';
import { SpaceBetween, Spinner, TextContent } from '@amzn/awsui-components-react/polaris';
import './loadingSpinnerStyles.scss';

interface Props {
  message?: string;
  size?: 'normal' | 'big' | 'large';
  absoluteCenter?: boolean;
  justify?: 'left' | 'right';
}

export default class LoadingSpinner extends React.Component<Props> {

  // Get size of text content based on spinner size.
  getMessageSize(message, size): any {
    const style = { className: 'policy--loading-spinner-message' };
    switch (size) {
      case 'normal': return <h5 {...style}>{message}</h5>;
      case 'big': return <h2 {...style}>{message}</h2>;
      case 'large': return <h1 {...style}>{message}</h1>;
      default: return <h5 {...style}>{message}</h5>;
    }
  }

  render() {
    const { message, size, absoluteCenter, justify } = this.props;
    const center = absoluteCenter ? 'policy--absolute-center' : '';

    return (
      <SpaceBetween direction="horizontal" size="m" className={`policy--loading-spinner-section ${center} ${justify}`}>
        <Spinner size={size} variant="disabled" />

        <span>
          {message &&
            <TextContent>
              {this.getMessageSize(message, size)}
            </TextContent>
          }
        </span>
      </SpaceBetween>
    );
  }
}
