import React from 'react';
import { SplitPanel } from '@amzn/awsui-components-react/polaris';
import AppStore from 'container/app/appStore';

interface Props {
  appStore?: AppStore;
}

const DocSplitPanel = (props: Props) : JSX.Element => {
  const { appStore } = props;

  return (
    <>
      {appStore?.splitpanel &&
        <SplitPanel
          header={appStore?.splitPanelHeader ?? ''}
          hidePreferencesButton
          closeBehavior={appStore.actionItemsSplitpanel ? 'hide' : 'collapse'}
        >
          {appStore?.splitPanelContent}
        </SplitPanel>
      }
    </>
  );
};

export default DocSplitPanel;
