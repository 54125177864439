import { observable, action } from 'mobx';
import { FieldGroupModel } from 'api/fieldModel';
import { FormFieldStore } from './formFieldStore';

export class FormFieldGroupStore {
  groupId = 'default';
  groupName = 'Default Group';
  @observable group: FieldGroupModel;
  @observable loading = false;
  @observable formFields: FormFieldStore[] = [];
  @observable expanded = true;

  constructor(groupId: string, groupName: string, group: FieldGroupModel, expanded = true) {
    this.groupId = groupId;
    this.groupName = groupName;
    this.group = group;
    this.expanded = expanded;
  }

  @action
  addField(formFieldStore: FormFieldStore) {
    this.formFields.push(formFieldStore);
  }

  @action
  removeField(id: string) {
    const index = this.formFields.findIndex(field => field.id === id);
    this.formFields.splice(index, 1);
  }
}
