import { action, computed, observable } from 'mobx';
import AppStore from 'container/app/appStore';
import { FormFieldStore } from './formFieldStore';
import { FormFieldGroupStore } from './formFieldGroupStore';
import { FieldGroupModel, FieldModel, FieldType } from '../api/fieldModel';
import { RequiredValidator } from './validators/requiredValidator';
import { LengthValidator } from './validators/lengthValidator';
import { FieldDependencyStore } from './fieldDependencyStore';
import { NILValidator } from './validators/nilValidator';
import { SizeValidator } from './validators/sizeValidator';
import { AttributeType, QuestionnaireType } from 'api/document';

export class FormStore {
  @observable formFieldsById = new Map<string, FormFieldStore>();
  @observable formGroupsById = new Map<string, FormFieldGroupStore>();
  @observable formFieldsByGroupId = new Map<string, FormFieldStore[]>();
  @observable appStore: AppStore;
  @observable customFieldStoresLoaded = false;

  model: any;
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  @computed
  get fields(): FormFieldStore[] {
    return Array.from(this.formFieldsById.values());
  }

  @computed
  get isFormModified() {
    return this.fields.some(field => field.modified)
      || this.fieldGroups.some(filedGroup => filedGroup.formFields.some(field => field.modified));
  }

  @computed
  get fieldGroups(): FormFieldGroupStore[] {
    return Array.from(this.formGroupsById.values());
  }

  @computed
  get currentFocused(): FormFieldStore {
    return this.fields.find(store => store.focused) as FormFieldStore;
  }

  @action
  async init(async = false, loadCustomFieldStore = true) {
    const fields = this.initFields();
    this.formFieldsById = new Map<string, FormFieldStore>();
    this.formGroupsById = new Map<string, FormFieldGroupStore>();
    this.formFieldsByGroupId = new Map<string, FormFieldStore[]>();
    this.addFieldGroupsToForm(fields);

    if (loadCustomFieldStore) {
      async ? await this.loadCustomFieldStore() : this.loadCustomFieldStore();
    }
    this.modelToForm();
    this.updateForm();
  }

  @action
  loadCustomFieldStore(): Promise<any[]> {
    const p = this.fields.map(f => f.field.customFieldStore?.load(f));
    this.customFieldStoresLoaded = true;
    return Promise.all(p);
  }

  @action
  setModel(model) {
    this.model = model;
  }

  @action
  clearFields() {
    this.init();
  }

  @action
  updateForm() {
    // use this hook to perform manual form update
    // e.g. convert a string value to a SelectOption
  }

  @action
  /* eslint-disable @typescript-eslint/no-unused-vars */
  listenFieldChange(id?: string, value?: any) {
    // use this hook to perform manual form update
    // e.g. convert a string value to a SelectOption
  }

  @action
  initFields(): FieldGroupModel[] {
    return [];
  }

  @action
  modelToForm() {
    if (this.model) {
      this.formFieldsById.forEach((formFieldStore, id) => {
        const value = this.getModelValue(id);
        if (value !== undefined) {
          this.modelFieldToForm(formFieldStore, value);
        }
      });
    }
  }

  @action
  formToModel(): any {
    const model = {};
    this.formFieldsById.forEach((formFieldStore, id) => {
      const value = this.getFieldValue(id);
      if (value !== undefined) {
        this.formFieldToModel(id, value, model);
      }
    });
    return model;
  }

  getModelValue(fieldId: string) {
    return this.model[fieldId];
  }

  getFieldValue(fieldId: string) {
    return this.formFieldsById.get(fieldId)?.field.value;
  }

  @action
  modelFieldToForm(formFieldStore: FormFieldStore, value: any) {
    formFieldStore.setOriginalValue(value);
  }

  formFieldToModel(id: string, value: any, model: any) {
    model[id] = value;
  }

  @action
  addFieldGroupsToForm(fieldGroups: FieldGroupModel[]) {
    fieldGroups.forEach(fieldGroup => {
      this.formGroupsById.set(fieldGroup.id, new FormFieldGroupStore(fieldGroup.id, fieldGroup.name, fieldGroup, fieldGroup.expanded));
      this.formFieldsByGroupId.set(fieldGroup.id, fieldGroup.fields?.map(field => new FormFieldStore(field, fieldGroup.id, true)));
      fieldGroup.fields?.forEach((field: FieldModel) => {
        const formFieldStore = new FormFieldStore(field, fieldGroup.id, true);
        FormStore.bindFieldValidators(formFieldStore);
        this.formFieldsById.set(field.id, formFieldStore);
        this.formGroupsById.get(fieldGroup.id)?.addField(formFieldStore);
        if (formFieldStore.field.fieldDependency) {
          formFieldStore.fieldDependencyStore = new FieldDependencyStore(formFieldStore.field.fieldDependency, formFieldStore, this.formFieldsById);
        }
      });
    });
  }

  @action
  removeFieldGroupFromForm(groupId: string) {
    this.formGroupsById.delete(groupId);
    this.formFieldsByGroupId.get(groupId)?.forEach(field => {
      this.formFieldsById.delete(field.id);
    });
    this.formFieldsByGroupId.delete(groupId);
  }

  @action
  static bindFieldValidators = (formFieldStore: FormFieldStore) => {
    const { field: { type, required } } = formFieldStore;
    if (required) {
      formFieldStore.validators.push(new RequiredValidator());
    } else {
      const index = formFieldStore.validators.findIndex(validator => validator instanceof RequiredValidator);
      if (index >= 0) {
        formFieldStore.validators.splice(index, 1);
      }
    }
    if (type === FieldType.TEXT || type === FieldType.TEXTAREA) {
      formFieldStore.validators.push(new LengthValidator());
      formFieldStore.validators.push(new NILValidator());
    }
    if (type === FieldType.SINGLEFILEUPLOAD) {
      formFieldStore.validators.push(new SizeValidator());
    }
  };

  @action
  setErrorMessage() {
    this.fields.forEach(field => {
      if (!field.isFieldValid) {
        field.setErrorText();
      }
    });
  }

  @computed
  get isFormValid() {
    return this.fields.every(field => field.isFieldValid);
  }

  @computed
  get isFormLoading() {
    return this.fields.some(field => field.loading) || this.fieldGroups.some(fieldGroup => fieldGroup.loading);
  }

  runFieldDependency() {
    this.fields.forEach(field => field.fieldDependencyStore?.run());
  }

  getQuestionnaireFromAttributeType = type => {
    switch (type) {
      case AttributeType.TEXT: return QuestionnaireType.TEXT;
      case AttributeType.RADIOGROUP: return QuestionnaireType.RADIOGROUP;
      case AttributeType.CHECKBOX: return QuestionnaireType.CHECKBOX;
      case AttributeType.TEXTAREA: return QuestionnaireType.TEXTAREA;
      case AttributeType.SELECT: return QuestionnaireType.SELECT;
      case AttributeType.DATE: return QuestionnaireType.DATE;
      case AttributeType.DATERANGE: return QuestionnaireType.DATERANGE;
      case AttributeType.MULTISELECT: return QuestionnaireType.MULTISELECT;
      case AttributeType.SINGLEUSER: return QuestionnaireType.SINGLEUSER;
      case AttributeType.MULTIPLEUSERS: return QuestionnaireType.MULTIPLEUSERS;
      case AttributeType.HEADING_ATTRIBUTE: return QuestionnaireType.HEADING_QUESTIONNAIRE;
      default: return type;
    }
  };
}
