/**
 * Manages all browser cookies.
 *
 * Reference:
 * https://github.com/reactivestack/cookies/tree/master/packages/react-cookie/#readme
 */

import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useCookies } from 'react-cookie';

export enum Cookie {
  /* **********************************************************************************************
   * Features
   ************************************************************************************************/

  // Determins if  feedback component in the topnav is displayed on page load.
  FEEDBACK = 'feedback',

  // Determins if the new version of Polaris is being used.
  USE_POLARIS_VR = 'usePolarisVR',

  // Determins if the side nav is closed by default on page load.
  SIDE_NAV_CLOSED = 'side-nav-closed',

  // Determins if the current user is viewing the advanced view of the document page.
  ADVANCED_VIEW_ACTIVE = 'advanced-view-active',

  /* **********************************************************************************************
   * Tutorial Components
   ************************************************************************************************/

  // Top-nav menu.
  NEW_FEATURE__MENU = 'new-feature--menu',

  // Determins if the tutorial component is being displayed for the Advanced View toggle button.
  NEW_FEATURE__DOCPAGE_REDESIGN = 'tutorial--docpage-redesign',

  // Determins if Dark Mode is active.
  DARK_MODE_ACTIVE = 'tutorial--dark-mode-active',
}

export const CookieStore = forwardRef((props: any, ref): any => {
  const [cookie, setCookie, removeCookie] = useCookies([...Object.values(Cookie)]);

  useEffect(() => {
    // Informs the App Store that the Cookie Store has finished mounting.
    if (props?.didMount) props.didMount(); /* eslint-disable-line */
  }, []);

  /**
   * Methods listed here can be accessed be other components through the appStore.
   */
  useImperativeHandle(ref, () => ({

    /**
     * Routes to method to set cookie.
     * @param {Cookie} type The name of the cookie to set.
     */
    setCookie(type: Cookie): void {
      switch (type) {
        case 'feedback':
          setFeedbackCookie();
          break;
        default: setCookie(type, Date.now(), { path: '/' });
      }
    },

    /**
     * Removes cookie.
     * @param {Cookie} type The name of the cookie to remove.
     */
    removeCookie(type: Cookie): void {
      removeCookie(type, { path: '/' });
    },

    /**
     * Checks if a cookie has already been set.
     * @param {Cookie} key The name of the cookie to check.
     * @returns {boolean} Determins if the cookie exists.
     */
    hasCookie(key: Cookie): boolean {
      return cookie[key] ?? false;
    },

    /**
     * Gets all cookies that have been set.
     * @returns {any} Existing cookies.
     */
    getCookies(): any {
      return cookie;
    },
  }));

  /**
   * Handles adding cookie for the feedback component.
   */
  const setFeedbackCookie = (): void => {
    const secondsInDay = 86400;
    const numberOfDays = 90;
    const secondsBeforeExpiration = secondsInDay * numberOfDays;
    setCookie(Cookie.FEEDBACK, Date.now(), { maxAge: secondsBeforeExpiration, path: '/' });
  };
});
