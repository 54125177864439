import { action, computed, observable } from 'mobx';

export enum Viewport {
  XLARGE = 1581,
  LARGE = 1281,
  MEDIUM = 900,
}

export default class AppLayoutStore {
  @observable topNav!: Element;
  @observable topNavRect!: DOMRect;
  @observable windowHeight: number = window.innerWidth;
  @observable windowWidth: number = window.innerHeight;
  @observable navigationOpen = true;
  DEFAULT_NAV_WIDTH = 250;


  @action
  setTopNav(topNav: Element): void {
    if (!topNav) return;
    this.topNav = topNav;
    this.topNavRect = topNav.getBoundingClientRect();
  }

  @action
  handleSetWindowHeight(): void {
    this.windowHeight = window.innerHeight;
  }

  @action
  handleSetWindowWidth(): void {
    this.windowWidth = window.innerWidth;
  }

  @action
  handleTopNavScroll(): void {
    this.topNavRect = this.topNav.getBoundingClientRect();
  }

  @action
  handleSetWNavigationOpen(navigationOpen?): void {
    this.navigationOpen = navigationOpen;
  }

  @computed
  get flyoverWidthM(): boolean {
    const winWidth = this.windowWidth;
    return winWidth! < Viewport.LARGE;
  }

  @computed
  get flyoverWidthL(): boolean {
    const winWidth = this.windowWidth;
    return winWidth! > Viewport.LARGE && winWidth < Viewport.XLARGE;
  }

  @computed
  get flyoverWidthXL(): boolean {
    const winWidth = this.windowWidth;
    return winWidth! > Viewport.XLARGE;
  }

  @computed
  get getNavWidth(): number {
    const winWidth = this.windowWidth;
    if (winWidth! > Viewport.LARGE) {
      return (this.DEFAULT_NAV_WIDTH);
    } if (winWidth! < Viewport.LARGE && winWidth! > Viewport.MEDIUM) {
      return (this.DEFAULT_NAV_WIDTH * 0.8);
    }
    return (this.DEFAULT_NAV_WIDTH * 0.7);
  }
}
