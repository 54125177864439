import React from 'react';

import './appPaddingStyles.scss';

export class AppPadding extends React.PureComponent<unknown, unknown> {
  render(): any {
    return (
      <div className="policy-padding-bottom" />
    );
  }
}
