import React from 'react';
import { action, observable } from 'mobx';
import { v4 as UUID } from 'uuid';
import { ErrorModel } from 'components/error/errorModel';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { sanitize } from 'api/security/sanitizer';

export interface FlashbarToastItems extends FlashbarProps.MessageDefinition {
  id: string;
  type?: 'success' | 'warning' | 'info' | 'error';
  content?: string;
  dismissible?: boolean;
  buttonText?: string;
}

export class AppToastAlertStore {
  @observable toastItems: FlashbarToastItems[] = [];

  @action
  removeAllItems() {
    this.toastItems = [];
  }

  @action
  removeFromItems(id: string) {
    const index = this.toastItems.findIndex(f => f.id === id);
    this.toastItems.splice(index, 1);
  }

  renderContent(message: string | React.ReactNode = 'Unknown error. Please try again.'): any {
    if (typeof message === 'string') {
      let msg = message;
      // add url to inclusive language website for nil error messages
      if (message.includes('non-inclusive')) {
        msg = message.concat('Click <a href="https://amazondei.na.lego.a2z.com/all-learning-and-support/inclusive-terminology#gdei" target="_blank" rel="noopener noreferrer" >here</a> for more details on non-inclusive language.');
      }

      return <div dangerouslySetInnerHTML={{ __html: sanitize(msg) }} />;
    } else return <>{message}</>;
  }

  @action
  addToastAlert(alert: ErrorModel | any, type: string, dismissible = true, options = {}, hasTimeout = false) {
    const id = UUID();
    const additionalOptions: any = {
      header: '',
      type,
      content: this.renderContent(alert?.message),
    };

    const newAlert = {
      id,
      dismissible,
      onDismiss: () => this.removeFromItems(id),
      ...options,
      ...additionalOptions,
    };
    this.toastItems = this.toastItems.concat(newAlert);

    // Auto dismiss if the alert type is success.
    if (type === 'success' || hasTimeout) {
      setTimeout(() => this.removeFromItems(id), 5000);
    }
    return newAlert;
  }

  @action
  updateItem(newItem: FlashbarToastItems) {
    this.toastItems = this.toastItems.map(item => (item.id === newItem.id ? newItem : item));
  }
}
