import * as React from 'react';
import { AppContainer } from './container/app/appContainer';
import Routes from './routes';
import { AppPadding } from 'container/util/appPadding';
import AppToastAlert from 'container/app/toastAlert/appToastAlert';

export class App extends React.PureComponent<unknown, unknown> {
  render(): any {
    return (
      <AppContainer>
        <Routes />
        <AppToastAlert />
        <AppPadding />
      </AppContainer>
    );
  }
}

export default App;
