/* eslint-disable */
import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { NAVIGATION } from './constants/navigation';
import { ErrorBoundary } from './container/util/errorBoundary';
import { PageContainer } from './container/page/pageContainer';
import configureCloudWatchRUM from 'utils/cloudWatchRUM';

export const routingStore = new RouterStore();

const TasksPage = React.lazy(() => import('pages/tasks/tasksPage'));
const BulkActionsPageLink = React.lazy(() => import('./pages/bulkActions/bulkActionsPage'));
const DBPage = React.lazy(() => import('./pages/dashboard/dbPage'));
const DocumentPage = React.lazy(() => import('./pages/document/docPage'));
const IntakeFormPage = React.lazy(() => import('./pages/document/inTakePage'));
const ManageFolderPage = React.lazy(() => import('./pages/folder/manage'));
const ProcessPage = React.lazy(() => import('./pages/process/processPageV2'));
const AdvancedSearchPage = React.lazy(() => import('./pages/search/searchPage'));
const NewRequestHandlerPage = React.lazy(() => import('./pages/document/components/newRequest/newRequestHandlerPage'));
const MigrationPage = React.lazy(() => import('./pages/migration/tasksPage'));
const SupplementalPage = React.lazy(() => import('./pages/document/supplementalPage'));
const NestedDocPage = React.lazy(() => import('./nestedRoutes'));

const routes = (): any => {
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, routingStore);

  configureCloudWatchRUM();

  return (
    <Router history={history}>
      <React.Suspense fallback={<PageContainer loading />}>
        <ErrorBoundary>
          <Switch>
            <Route exact path={NAVIGATION.FOLDER_MANAGEMENT_PAGE.PATH} component={ManageFolderPage} />
            <Route exact path={NAVIGATION.LANDING_PAGE.PATH} component={DBPage} />
            <Route exact path={NAVIGATION.DASHBOARD_PAGE.PATH} component={DBPage} />
            <Route exact path={NAVIGATION.DASHBOARD_PAGE.ALIAS_PATH} component={DBPage} />
            <Route exact path={NAVIGATION.DOCUMENT_PAGE.PATH} component={NestedDocPage} />
            <Route isExact path={NAVIGATION.NEW_INTAKE_PAGE.PATH} component={NewRequestHandlerPage} />
            <Route exact path={NAVIGATION.INTAKE_FORM_PAGE.PATH} component={IntakeFormPage} />
            <Route exact path={NAVIGATION.PROCESS_PAGE.PATH} component={ProcessPage} />
            <Route exact path={NAVIGATION.SIMPLE_SEARCH_PAGE.PATH} component={AdvancedSearchPage} />
            <Route exact path={NAVIGATION.TASKBOARD_PAGE.PATH} component={TasksPage} />
            <Route exact path={NAVIGATION.ACKNOWLEDGMENTS_PAGE.PATH} component={TasksPage} />
            <Route exact path={NAVIGATION.BULK_ACTIONS.PATH} component={BulkActionsPageLink} />
            <Route exact path={NAVIGATION.MIGRATION_PAGE.PATH} component={MigrationPage} />
          </Switch>
        </ErrorBoundary>
      </React.Suspense>
    </Router>
  );
};

export default routes;
