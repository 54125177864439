import { FormFieldStore } from 'form/formFieldStore';
import { action } from 'mobx';
import { FieldType } from 'api/fieldModel';
import { FieldValidator } from './fieldValidator';
import validateInclusiveTerms from '@amzn/inclusive-tech-language-rules-js-wrapper';

export class NILValidator implements FieldValidator {
  validate = (formFieldStore: FormFieldStore) => {
    const store = formFieldStore;
    const { field: { value, type, description } } = store;
    if (type === FieldType.TEXT || type === FieldType.TEXTAREA) {
      const isIterable = val => Symbol.iterator in Object(val);
      // return !(value && isIterable(value) && validateInclusiveTerms(value.concat(description)).length > 0);
      return !(value && isIterable(value) && this.strippedHTMLValidate(value.concat(description)));
    } else {
      return true;
    }
  };
  strippedHTMLValidate(value: string): boolean {
    const reg = new RegExp('<[^>]*>', 'g');
    const replacedStr = value.replace(reg, '');
    return validateInclusiveTerms(replacedStr).length > 0;
  }

  @action
  getErrorText = (formFieldStore: FormFieldStore): string => {
    const store = formFieldStore;
    const { field: { value } } = store;
    const errorMsg = this.getViolationsAsString(value);
    return errorMsg;
  };

  getViolationsAsString(value: string): string {
    const violations = validateInclusiveTerms(value);
    let errorMsg = '';
    violations.forEach(violation => {
      errorMsg += `"${violation.phrasing}" is a non-inclusive word, we suggest using these: ${violation.alternative_terms.join(', ')}.\n `;
    });
    return errorMsg;
  }
}
