import { observable, action } from 'mobx';
import { FormStore } from '../../form/formStore';
import AppStore from '../../container/app/appStore';

// eslint-disable-next-line no-shadow
export enum ModalActionType {
  PRIMARY = 'primary',
  CANCEL = 'cancel'
}

export class ModalStore extends FormStore {
  @observable isVisible = false;
  @observable loading = false;
  @observable appStore!: AppStore;
  @observable disablePrimary = false;
  @observable activePageIndex = 0;
  header: string;

  constructor(header: string, appStore: AppStore, isVisible = false) {
    super(appStore);
    this.header = header;
    this.appStore = appStore;
    this.isVisible = isVisible;
  }

  @action
  setVisible(visible: boolean) {
    this.isVisible = visible;
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  performAction(actionType: ModalActionType, persistOnPrimaryError = false) {
    if (actionType === ModalActionType.CANCEL) {
      this.setVisible(false);
    }
    if (actionType === ModalActionType.PRIMARY) {
      if (!persistOnPrimaryError) this.setVisible(false);
    }
  }

  /**
   * Sets the index of the currently active page if the pages feature is enabled.
   * @param {number} newPageIndex The new page index that should be displayed in the modal.
   */
  @action
  setActivePageIndex(newPageIndex: number): void {
    this.activePageIndex = newPageIndex;
  }
}
