import React from 'react';
import { Flashbar } from '@amzn/awsui-components-react/polaris';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import * as ReactDOM from 'react-dom';
import AppStore from '../appStore';
import './toastAlertStyles.scss';

interface Props {
  appStore?: AppStore;
}

@inject('appStore') @observer
export default class AppToastAlert extends React.Component<Props, unknown> {
  renderToast() {
    const { appStore } = this.props;
    const { appToastAlertStore: store } = appStore!;
    const classnames = classNames(
      'policy--toast-alert',
      { 'policy--toast-alert-isOpen': store.toastItems.length > 0 },
      { 'policy--toast-alert-isClosed': !(store.toastItems.length > 0) },
    );
    return (
      <div className={classnames}>
        <Flashbar items={store.toastItems} />
      </div>
    );
  }

  render() {
    const root = document.getElementById('root');
    if (!root) {
      return null;
    }
    return (
      ReactDOM.createPortal(this.renderToast(), root,)
    );
  }
}
