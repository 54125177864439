import { FormFieldStore } from './formFieldStore';
import { action, computed, observable } from 'mobx';
import { FieldDependencyModel } from 'api/fieldModel';
import _ from 'lodash';
import { FormStore } from './formStore';

export class FieldDependencyStore {
  @observable formFields: Map<string, FormFieldStore>;
  @observable dependent: FormFieldStore;
  originalHiddenness?: boolean;
  originalRequiredness?: boolean;
  originalValue?: any;
  fieldDependency: FieldDependencyModel;

  constructor(fieldDependency: FieldDependencyModel, dependent: FormFieldStore, formFields: Map<string, FormFieldStore>) {
    this.formFields = formFields;
    this.fieldDependency = fieldDependency;
    this.originalHiddenness = dependent.field.hidden;
    this.originalRequiredness = dependent.field.required;
    this.originalValue = _.cloneDeep(dependent.field.value);
    this.dependent = dependent;
  }

  @computed
  get compareCondtions() {
    switch (this.fieldDependency.operation) {
      case 'AND': return this.everyConditionMet;
      case 'OR': return this.anyConditionMet;
      default: return false;
    }
  }

  @computed
  get everyConditionMet() {
    return this.fieldDependency.conditions.every(condition => {
      if (condition.conditionCheck) {
        return condition.conditionCheck(condition.value, this.formFields.get(condition.fieldId)?.field.value);
      } else {
        return _.isEqual(this.formFields.get(condition.fieldId)?.field.value, condition.value);
      }
    });
  }

  @computed
  get anyConditionMet() {
    return this.fieldDependency.conditions.some(condition => {
      if (condition.conditionCheck) {
        return condition.conditionCheck(condition.value, this.formFields.get(condition.fieldId)?.field.value);
      } else {
        return _.isEqual(this.formFields.get(condition.fieldId)?.field.value, condition.value);
      }
    });
  }

  @action
  executeAction() {
    for (const a of this.fieldDependency.actions) {
      switch (a) {
        case 'MAKE_VISIBLE':
          this.dependent.field.hidden = false;
          break;
        case 'MAKE_HIDDEN':
          this.dependent.field.hidden = true;
          this.dependent.field.value = this.originalValue;
          break;
        case 'MAKE_REQUIRED':
          this.dependent.field.required = true;
          FormStore.bindFieldValidators(this.dependent);
          break;
      }
    }
  }

  @action
  reset() {
    this.dependent.field.hidden = this.originalHiddenness;
    this.dependent.field.required = this.originalRequiredness;
    if (this.originalHiddenness) {
      this.dependent.field.value = this.originalValue;
    }
    FormStore.bindFieldValidators(this.dependent);
  }

  run() {
    this.compareCondtions ? this.executeAction() : this.reset();
  }
}
