import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import * as React from 'react';


interface Props {
  loading: boolean;
}

export class PageContainer extends React.PureComponent<Props, unknown> {
  render(): any {
    const { loading } = this.props;
    const isSearchPage = window.location.pathname.includes('/search/');

    return (
      <>
        {loading && (
          <LoadingSpinner size="big" message={isSearchPage ? 'Loading search page...' : ''} absoluteCenter />
        )}
      </>
    );
  }
}
