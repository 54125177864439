import { Document } from 'api/document';
import { Folder } from 'api/folder';

export type DocumentMetadata = {
  pageNumber: number
  pageSize: number
  totalItems: number
  totalPages: number
}

export class DocumentWrapper {
  documents: Document[];
  isLoading: boolean;
  selectedFolder: Folder | null;
  documentMetadata: DocumentMetadata;

  constructor(loading, documents, selectedFolder, documentMetadata?) {
    this.isLoading = loading;
    this.documents = documents;
    this.selectedFolder = selectedFolder;
    this.documentMetadata = documentMetadata;
  }
}
