import { observable, action, computed } from 'mobx';
import { FieldModel } from 'api/fieldModel';
import { FieldValidator } from './validators/fieldValidator';
import { FieldDependencyStore } from './fieldDependencyStore';

export class FormFieldStore {
  @observable field: FieldModel;
  @observable originalValue: any;
  @observable modified = false;
  @observable groupId: string;
  @observable editable = true;
  @observable loading = false;
  @observable errorText = '';
  @observable focused?: boolean;
  @observable isBackDate?: boolean;
  @observable validators: FieldValidator[] = [];
  @observable fieldDependencyStore?: FieldDependencyStore;

  constructor(field: FieldModel, groupId: string, editable: boolean) {
    this.field = field;
    this.groupId = groupId;
    this.editable = editable;
    this.focused = false;
  }

  @action
  modifyValue(value: any) {
    this.field.value = value;
    this.modified = true;
    this.setErrorText();
  }

  @action
  setOriginalValue(value: any) {
    this.originalValue = value;
    this.field.value = value;
    this.setErrorText();
  }

  @action
  setFocused(focused: boolean) {
    this.focused = focused;
  }

  @computed
  get id(): string {
    return this.field.id;
  }

  @computed
  get isFieldValid() {
    return this.validators.every(validator => validator.validate(this));
  }

  @action
  setErrorText() {
    let errorText = '';
    this.validators.forEach(validator => {
      if (!validator.validate(this)) {
        errorText = errorText || validator.getErrorText(this);
      }
    });
    this.errorText = errorText;
  }
}
