import { FormFieldStore } from 'form/formFieldStore';
import { action } from 'mobx';
import { FieldValidator } from './fieldValidator';
import { AttributeType } from 'api/document';

export class RequiredValidator implements FieldValidator {
  validate = (formFieldStore: FormFieldStore): boolean => {
    const store = formFieldStore;
    const { field: { value, type } } = store;
    if (value === undefined || value === null) {
      return false;
    }

    // Text fields
    if (typeof value === 'string' && type !== AttributeType.HEADING_ATTRIBUTE) {
      return value !== '';
    }

    if (type === AttributeType.SINGLEUSER || type === AttributeType.SELECT) {
      if (value.id) return value.id?.length;
      return value.value?.length;
    }

    if (type === AttributeType.MULTIPLEUSERS || type === AttributeType.MULTISELECT) {
      return value.length;
    }

    return true;
  };

  @action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getErrorText = (formFieldStore: FormFieldStore) => 'Field is required';
}
