import dompurify from 'dompurify';

const allowedProtocols = ['https'];

export function sanitize(html: string) {
  const sanitizedHtml = dompurify.sanitize(html, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allowfullscreen', 'scrolling'],
    ALLOW_UNKNOWN_PROTOCOLS: false,
    ALLOWED_PROTOCOLS: allowedProtocols,
    ALLOWED_ATTR: [
      'allowfullscreen',
      'alt',
      'align',
      'bgcolor',
      'border',
      'cellpadding',
      'cellspacing',
      'cite',
      'class',
      'color',
      'cols',
      'colspan',
      'content',
      'data',
      'data-operation-index',
      'datetime',
      'dir',
      'download',
      'headers',
      'height',
      'hidden',
      'href',
      'hreflang',
      'id',
      'ismap',
      'lang',
      'list',
      'name',
      'rel',
      'reversed',
      'rows',
      'rowspan',
      'scope',
      'size',
      'sizes',
      'span',
      'spellcheck',
      'src',
      'srcset',
      'start',
      'style',
      'tabindex',
      'target',
      'title',
      'translate',
      'type',
      'value',
      'width',
      'wrap',
    ],
  });
  return sanitizedHtml?.replace(/policy.fintech.amazon.dev/g, 'policy.a2z.com');
}
