import { Folder } from './folder';
import { CustomFieldStore } from '../form/customFieldStore';
import { RadioGroupItem } from 'fields/radioGroupField/radioGroupModel';
import { SelectOption } from 'fields/selectField/selectModel';
import { AttributeType } from './document';

export interface FieldModel {
  name: any;
  value?: any;
  type: FieldType | AttributeType;
  required?: boolean;
  id: string;
  description?: string;
  placeholder?: string;
  customFieldStore?: CustomFieldStore;
  previewMode?: boolean;
  disabled?: boolean;
  followRowLayout?: boolean;
  customClassName?: string;
  i18nStrings?: Map<string, any>;

  // radio group
  items?: RadioGroupItem[];

  // select field
  options?: SelectOption[];

  // Tree Field
  rootFolder?: Folder[];
  allowStaticSearch?: boolean;

  // client side only
  hidden?: boolean;
  fieldDependency?: FieldDependencyModel;

  // text field
  textType?: string;

  // date picker
  isDateEnabled?: (date: Date) => boolean;

  // file upload field
  constraintText?: string;
  constraintTypes?: string;
  fileSizeLimit?: number;

  rangeSelectorMode?: string;
  dateOnly?: boolean;

  denyUpdateFieldProperties?: boolean;
  denyUpdateFieldResponse?: boolean;
  isCloned?: boolean;
  isPublicResponse?: boolean;

  // Subscriptions
  tokenLimit?;
  hideTokens?: boolean;
  optionsDisabled?: boolean;
  isSubscriptions?: boolean;
  customErrorText?: (value: any) => string;

  popover?: { content: any, trigger: any };

  // Allows different forms to be created using different fields from the same form store.
  group?: { id: string };

  // Date Range Picker
  expandToViewport?: boolean;

  // Used for risk ranked questionnaires.
  isWeighted?: boolean;
  // Used for risk rank exceptions where we do not want to show backdates
  isBackDate?: boolean;
}

export interface FieldGroupModel {
  name: string;
  id: string;
  fields: FieldModel[];
  expanded: boolean;
}

// eslint-disable-next-line no-shadow
export enum FieldType {
  TEXT = 'TextField',
  TREE = 'TreeField',
  AUTOSUGGEST = 'AutosuggestField',
  CHECKBOX = 'CheckboxField',
  RADIOGROUP = 'RadioGroupField',
  TEXTAREA = 'TextAreaField',
  SELECT = 'SelectField',
  MULTISELECT = 'MultiSelectField',
  SINGLESELECT = 'SingleSelectField',
  DOCSEARCH = 'SearchDocumentField',
  SINGLEFILEUPLOAD = 'SingleFileUpload',
  MULTIFILEUPLOAD = 'MultiFileUpload',
  OPTIONSSELECT = 'OptionsSelectField',
  DATE = 'DateField',
  DATERANGE = 'DateRangeField',
  MULTIPLEUSERS = 'MultipleUsers',
  DATEPICKER = 'DatePicker',
  TIMEINPUT = 'TimeInput',
  BUTTON = 'Button',
  DIVIDER = 'Divider',
  BANNER = 'Banner',
  WEIGHTED_OPTIONS = 'weightedOptions',
}

export interface FieldDependencyModel {
  conditions: FieldDependency[];
  operation: 'AND' | 'OR';
  actions: ('MAKE_VISIBLE' | 'MAKE_REQUIRED' | 'MAKE_HIDDEN')[];
}


export interface FieldDependency {
  fieldId: string;
  value: any;
  conditionCheck?: (conditionValue: any, dependentValue: any) => boolean;
}
