/* istanbul ignore file */

const URL_TO_STAGE = {
  'localhost:3000': 'dev',
  'dlcce97v516qb.cloudfront.net': 'alpha',
  'dtjp19k85n8vx.cloudfront.net': 'beta',
  'd3dkpx48dsyibc.cloudfront.net': 'gamma',
  'd1cm1ee2dkvox6.cloudfront.net': 'prod',
  'd94i6jw2ho26m.cloudfront.net': 'secgov',
  'd1r1301rn47fwm.cloudfront.net': 'risk',
};

/* eslint-disable camelcase */
const AMPLIFY_CONFIG = {
  alpha: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:8a985eff-6799-4d97-ba9c-6f5531425892',
      userPoolId: 'us-east-1_BnU4bol2G',
      userPoolWebClientId: 'vj2tivlfcgck9d6mt276osfo9',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-alpha.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'https://alpha.policy.a2z.com',
      redirectSignOut: 'https://alpha.policy.a2z.com',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://ufintkrcmvb3tfa3chzn6chkdu.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
  beta: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:0bf9c2ee-d8be-4aa0-93c6-685c3356699e',
      userPoolId: 'us-east-1_xW69bkDmz',
      userPoolWebClientId: '1ajfpqmn46e95213mogkdmucbf',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-beta.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'https://beta.policy.a2z.com',
      redirectSignOut: 'https://beta.policy.a2z.com',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://2sxqrd4dr5fv3fhglxof64n4oi.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
  gamma: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:08fb3ffe-48d2-42b5-9357-a31582515da5',
      userPoolId: 'us-east-1_nXjQbgc66',
      userPoolWebClientId: '2ijp3t3ngpq866l9liohon1hli',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-gamma.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'https://gamma.policy.a2z.com',
      redirectSignOut: 'https://gamma.policy.a2z.com',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://2wb4a63tvjfzbnlo4dfig2wmum.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
  prod: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:d24de118-53a4-4ae2-b14e-162bd86fdb6a',
      userPoolId: 'us-east-1_b2fnqR6Jz',
      userPoolWebClientId: '719db9mpbpmverr5veb998kbd8',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-prod.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'https://policy.a2z.com',
      redirectSignOut: 'https://policy.a2z.com',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://rknphatu5rb4vm4h5vmqwe4vba.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
  dev: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:8a985eff-6799-4d97-ba9c-6f5531425892',
      userPoolId: 'us-east-1_BnU4bol2G',
      userPoolWebClientId: 'vj2tivlfcgck9d6mt276osfo9',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-alpha.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'http://localhost:3000',
      redirectSignOut: 'http://localhost:3000',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://ufintkrcmvb3tfa3chzn6chkdu.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
  secgov: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:d24de118-53a4-4ae2-b14e-162bd86fdb6a',
      userPoolId: 'us-east-1_b2fnqR6Jz',
      userPoolWebClientId: '719db9mpbpmverr5veb998kbd8',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-prod.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'https://secgov.fintech.amazon.dev',
      redirectSignOut: 'https://secgov.fintech.amazon.dev',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://rknphatu5rb4vm4h5vmqwe4vba.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
  risk: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:08fb3ffe-48d2-42b5-9357-a31582515da5',
      userPoolId: 'us-east-1_nXjQbgc66',
      userPoolWebClientId: '2ijp3t3ngpq866l9liohon1hli',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-gamma.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'https://risk.gamma.policy.a2z.com',
      redirectSignOut: 'https://risk.gamma.policy.a2z.com',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://2wb4a63tvjfzbnlo4dfig2wmum.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
  proda2z: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:d24de118-53a4-4ae2-b14e-162bd86fdb6a',
      userPoolId: 'us-east-1_b2fnqR6Jz',
      userPoolWebClientId: '719db9mpbpmverr5veb998kbd8',
      mandatorySignIn: true,
    },
    oauth: {
      domain: 'policymanagement-prod.auth.us-east-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: 'https://policy.a2z.com',
      redirectSignOut: 'https://policy.a2z.com',
      responseType: 'code',
    },
    API: {
      aws_appsync_region: 'us-east-1',
      aws_appsync_graphqlEndpoint: 'https://rknphatu5rb4vm4h5vmqwe4vba.appsync-api.us-east-1.amazonaws.com/graphql',
    },
  },
};

let Stage;
const PATH = 'policy.a2z.com';
const SECGOVPATH = 'secgov.fintech.amazon.dev';
const A2ZPATH = 'policy.a2z.com';
const RISKPATH = 'risk.gamma.policy.a2z.com';

if (window.location.host in URL_TO_STAGE) {
  Stage = URL_TO_STAGE[window.location.host];
} else if (window.location.host === PATH) {
  Stage = 'prod';
} else if (window.location.host === SECGOVPATH) {
  Stage = 'secgov';
} else if (window.location.host === A2ZPATH) {
  Stage = 'proda2z';
} else if (window.location.host === RISKPATH) {
  Stage = 'risk';
} else if (window.location.host.endsWith(PATH)) {
  const { host } = window.location;
  [Stage] = host.split('.');
} else {
  Stage = 'dev';
}

const appConfig = {
  Stage,
  AmplifyConfig: AMPLIFY_CONFIG[Stage],
};

export default appConfig;
