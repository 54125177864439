import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { ElementType } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import { getFolders } from 'api/graphql/query';
import { Spinner, Input, Icon, TextContent } from '@amzn/awsui-components-react/polaris';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TreeItem } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import './tree.scss';
import { DbFolderContainerStore } from 'pages/dashboard/components/dbFolderContainer/dbFolderContainerStore';
import { Folder } from 'api/folder';

const BoxComponent: any = Box;

interface Props {
  header?: any;
  allowStaticSearch?: boolean;
  nodes: any[];
  loading: boolean;
  onSelected?(value: any): void;
  labelIcon?: ElementType;
  nameField?: string;
  childField?: string;
  classNames?: string;
  treeViewDefaults?: any;
  storeItem?: DbFolderContainerStore;
  defaultSelected?: any;
}

@observer
export default class Tree extends React.Component<Props> {
  @observable searchText = '';
  @observable displayNodes: any[] = [];
  @observable expandedNodes: string[] = [];
  @observable defaultSelected: any = '';
  @observable updateTimeout: ReturnType<typeof setTimeout> | null = null;
  @observable selected = '';

  @action
  componentDidMount(): void {
    this.displayNodes = this.props.nodes;
  }

  @action
  componentDidUpdate(prevProps: Props) {
    if (prevProps.nodes !== this.props.nodes) {
      this.displayNodes = this.props.nodes;
    }
  }


  @action.bound
  handleChange(node) {
    const { onSelected } = this.props;
    if (onSelected) {
      onSelected(node);
    }
  }

  @action
  setDisplayNodes(nodes) {
    this.displayNodes = nodes;
    // if (this.props.storeItem?.folders) this.props.storeItem.folders = nodes;
  }

  @action
  getSearchResult(params) {
    const { storeItem } = this.props;
    if (storeItem?.expandedNodes) storeItem.expandedNodes = [];
    this.props.storeItem?.appStore.api.query(getFolders, params)
      .then(action(value => {
        if (!value.data.getFolders) return;
        const folders = value.data.getFolders.map(folder => new Folder(folder));
        if (!this.searchText) {
          this.setDisplayNodes(this.props.storeItem?.folders);
          if (storeItem?.expandedNodes) storeItem.expandedNodes = [];
          return;
        }
        for (const folder of folders) {
          const parentFolder: Folder[] = [];
          folder.parentFolders.forEach((item: any) => {
            parentFolder.push(new Folder(item));
          });
          folder.parentFolders = parentFolder;
        }
        const hashTable = new Map();
        const root: Folder[] = [];
        for (const target of folders) {
          if (target.parentFolders.length) {
            let prevFolderId = '';
            for (const parent of target.parentFolders) {
              if (!hashTable.has(parent.id)) {
                const parentItem = new Folder(parent);
                hashTable.set(parent.id, parentItem);
                if (prevFolderId) {
                  parentItem.parentFolderId = prevFolderId;
                }
                prevFolderId = parentItem.id;
              }
            }
          }
          hashTable.set(target.id, new Folder(target));
        }
        for (const [, targetFolder] of hashTable) {
          if (targetFolder?.parentFolderId) {
            const subFolderList = hashTable.get(targetFolder.parentFolderId).subFolders;
            const matchIndex = this.isFolderInList(subFolderList, targetFolder);
            if (matchIndex === -1) {
              subFolderList.push(targetFolder);
            } else {
              subFolderList.splice(matchIndex, 1, targetFolder);
            }
          } else {
            root.push(hashTable.get(targetFolder.id));
          }
          this.props.storeItem?.expandedNodes.push(targetFolder.id);
        }
        this.setDisplayNodes(root);

      }));
  }

  @action.bound
  handleSearch(e) {
    if (this.updateTimeout) clearTimeout(this.updateTimeout);
    this.searchText = e.detail.value.toLowerCase();
    this.props?.storeItem?.setSearchText(this.searchText);
    this.displayNodes = [];
    // this code below forces the tree re-render so the expanded setting takes effect
    const params = this.searchText ? { loadAll: false, accessTypes: 'read,write,admin', folderName: this.searchText } : { loadAll: true, accessTypes: 'read,write,admin' };
    this.updateTimeout = setTimeout(action(() => {
      this.getSearchResult(params);
    }), 750);
  }

  isFolderInList(folderList, targetFolder) {

    for (let i = 0; i < folderList.length; i++) {
      if (folderList[i].id === targetFolder.id) return i;
    }
    return -1;
  }

  @action
  containsText(node: any, searchText: string) {
    const children = node[this.childField].filter(child => this.containsText(child, searchText));
    if (children && children.length > 0) {
      this.expandedNodes.push(node.id);
      node[this.childField] = children;
      return true;
    }
    if (node[this.nameField].toLowerCase().includes(searchText)) {
      return true;
    }
    return false;
  }

  get nameField() {
    const { nameField } = this.props;
    return nameField ? nameField : 'name';
  }

  get childField() {
    const { childField } = this.props;
    return childField ? childField : 'children';
  }

  get expandedNodeIds() {
    const { treeViewDefaults } = this.props;
    if (this.searchText) {
      return this.expandedNodes.reverse();
    }
    return treeViewDefaults?.defaultExpanded || [];
  }

  renderTree(node) {
    const { labelIcon } = this.props;
    let childItems;
    if (node[this.childField]?.length) {
      childItems = node[this.childField].map(child => this.renderTree(child));
    }

    return (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        className={this.props.classNames}
        label={
          <div className={node[this.nameField]}>
            <BoxComponent sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
              {labelIcon && (<BoxComponent component={labelIcon} color="inherit" sx={{ mr: 1 }} />)}
              <TextContent className={node[this.nameField]}>
                <span className="policy--tree-item-text">{node[this.nameField]}</span>
              </TextContent>
            </BoxComponent>
          </div>
        }
        onClick={() => { this.handleChange(node); }}
      >
        {childItems}
      </TreeItem>
    );
  }

  /**
   * Sets the selected folder.
   * @param {string} nodeId Id of the new selected folder.
   */
  @action
  handleSelect(nodeId: string): void {
    this.selected = nodeId;
  }

  render() {
    const { loading, header, allowStaticSearch, nodes, treeViewDefaults, defaultSelected } = this.props;
    this.handleSelect(defaultSelected);

    if (loading) return <Spinner className="policy--spinner" size="large" />;

    if (!nodes || nodes.length === 0) {
      return (
        <div className="policy--tree">
          <div className="policy--tree-header">
            {header}
            {allowStaticSearch && (<Input
              type="search"
              value={this.searchText}
              onChange={val => this.handleSearch(val)}
              placeholder="Filter folders"
            />)}
          </div>
          <div className="policy--document-none">
            <br />
            <div className="policy--folder-tree-none-message">
              <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
              <Icon className="policy--document-icon-warning" id="folder-icon" name="folder-open" size="large" />
              <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
            </div>
            <p className="policy--folder-tree-none-message" id="text-display"> There is no information to display here because you do not have permission to any folders. To get started select &quot;Add&quot; or ask you manager to grant permission to your org&apos;s folders.</p>
          </div>
        </div>
      );
    }
    const foldersToRender = this.displayNodes; /* this.props.storeItem?.folders ?? */

    return (
      <div className="policy--tree">
        <div className="policy--tree-header">
          {header}
          {allowStaticSearch && (
            <Input
              type="search"
              value={this.searchText}
              onChange={val => this.handleSearch(val)}
              placeholder="Filter folders"
            />
          )}
        </div>
        <section className="policy--tree-list">
          {!!this.displayNodes && this.displayNodes.length > 0 ? (
            <TreeView
              selected={this.selected}
              onNodeSelect={(_, nodeId) => this.handleSelect(nodeId)}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              defaultSelected={treeViewDefaults?.defaultSelected || []}
              defaultExpanded={this.expandedNodeIds}
              expanded={this.props.storeItem?.expandedNodes}
            >
              {foldersToRender.map(node => this.renderTree(node))}
            </TreeView>
          ) : (
            <TextContent>
              <h5 id="policy--no-folders-available">
                No folders available.
              </h5>
            </TextContent>
          )}
        </section>
      </div>
    );
  }
}
