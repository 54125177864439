import { FormFieldStore } from 'form/formFieldStore';
import { action } from 'mobx';
import { FieldValidator } from './fieldValidator';

export class SizeValidator implements FieldValidator {
  validate = (formFieldStore: FormFieldStore) => {
    const store = formFieldStore;
    const { field: { value, fileSizeLimit } } = store;
    if (fileSizeLimit === undefined || fileSizeLimit === null) {
      return true;
    }
    if (value && value.length > 0) {
      const fileSize = value[0].size / 1024 / 1024; // in MiB
      return fileSize < fileSizeLimit;
    }
    return true;
  };

  @action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getErrorText = (formFieldStore: FormFieldStore) => 'File size can not excced the allowed limit';
}
