import { FieldGroupModel, FieldType } from 'api/fieldModel';
import { ModalStore } from 'components/modal/modalStore';
import AppStore, { AccessType } from 'container/app/appStore';
import { SelectDocStore } from 'fields/selectField/selectDocStore';
import { SelectFolderStore } from 'fields/selectField/selectFolderStore';
import { action, observable } from 'mobx';

export enum DocumentFieldID {
  DOCUMENT_TYPE = 'documentType',
  IS_TEMPLATE = 'isTemplate',
  NAME = 'title',
  DESCRIPTION = 'description',
  PARENT_ID = 'parentFolderId',
  TEMPLATE_ID = 'parentTemplateId',
  CLONE_PERMISSION = 'clonePermission',
}

export class CreateDocumentModalStore extends ModalStore {
  @observable documentType: string;
  @observable templateIdentifier = false;

  constructor(header: string, appStore: AppStore, documentType: string, templateIdentifier?: boolean) {
    super(header, appStore);
    this.documentType = documentType;
    this.templateIdentifier = templateIdentifier ? templateIdentifier : false;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  listenFieldChange(fieldID: string, value: any) {
    const clonePermissionField = this.formFieldsById.get(DocumentFieldID.CLONE_PERMISSION)?.field;
    const parentFolderId = this.formFieldsById.get(DocumentFieldID.PARENT_ID)?.field;
    const parentTemplateId = this.formFieldsById.get(DocumentFieldID.TEMPLATE_ID)?.field;
    if (fieldID !== DocumentFieldID.PARENT_ID
      && fieldID !== DocumentFieldID.TEMPLATE_ID) {
      return;
    }
    if (clonePermissionField) {
      if (parentTemplateId?.value) {
        clonePermissionField.disabled = false;
        clonePermissionField.required = true;
        clonePermissionField.name = 'Clone permissions from selected folder';
        clonePermissionField.description = 'If unchecked - document will clone permissions from selected template';
        clonePermissionField.value = true;
        clonePermissionField.hidden = false;
      } else if (parentFolderId?.value) {
        clonePermissionField.disabled = false;
        clonePermissionField.name = 'Clone permissions from parent folder';
        clonePermissionField.value = true;
        clonePermissionField.required = false;
        clonePermissionField.hidden = false;
      } else {
        clonePermissionField.disabled = false;
        clonePermissionField.name = 'Clone permissions from parent folder';
        clonePermissionField.value = false;
        clonePermissionField.required = false;
        clonePermissionField.hidden = true;
        clonePermissionField.hidden = false;
      }
    }
  }

  @action
  updateForm() {
    const clonePermissionField = this.formFieldsById.get(DocumentFieldID.CLONE_PERMISSION)?.field;
    const parentFolderId = this.formFieldsById.get(DocumentFieldID.PARENT_ID)?.field;
    if (clonePermissionField) {
      if (parentFolderId?.value) {
        clonePermissionField.disabled = false;
        clonePermissionField.name = 'Clone permissions from parent folder';
        clonePermissionField.value = true;
        clonePermissionField.required = false;
        clonePermissionField.hidden = false;
      }
    }
  }

  initFields(): FieldGroupModel[] {
    const docSearchStore = new SelectDocStore(this.appStore, this.documentType, true, true);

    const nameField = {
      id: DocumentFieldID.NAME,
      name: 'Title',
      description: '',
      type: FieldType.TEXT,
      placeholder: 'Title...',
      required: true,
      value: null,
    };
    const descriptionField = {
      id: DocumentFieldID.DESCRIPTION,
      name: 'Description',
      description: '',
      type: FieldType.TEXTAREA,
      placeholder: 'Description...',
      required: false,
      value: null,
    };
    const treeField = {
      id: DocumentFieldID.PARENT_ID,
      name: 'Selected parent folder',
      description: 'This is the parent folder to create your document in',
      type: FieldType.SELECT,
      placeholder: 'Select parent folder',
      required: true,
      value: this.appStore.selectedFolder ? { value: this.appStore.selectedFolder.id, label: this.appStore.selectedFolder.folderName } : null,
      customFieldStore: new SelectFolderStore(this.appStore, AccessType.WRITE),
    };
    const templateField = {
      id: DocumentFieldID.TEMPLATE_ID,
      name: 'Choose template',
      description: 'Select template by name or id',
      type: FieldType.SELECT,
      placeholder: 'Search...',
      required: false,
      options: [],
      hidden: this.templateIdentifier,
      customFieldStore: docSearchStore,
    };
    const documentTypeField = {
      id: DocumentFieldID.DOCUMENT_TYPE,
      name: '',
      description: '',
      type: FieldType.TEXT,
      required: true,
      hidden: true,
      value: this.documentType,
    };
    const isTemplateField = {
      id: DocumentFieldID.IS_TEMPLATE,
      name: '',
      description: '',
      type: FieldType.TEXT,
      required: false,
      hidden: true,
      value: this.templateIdentifier,
    };

    const clonePermissionField = {
      id: DocumentFieldID.CLONE_PERMISSION,
      name: 'Clone permissions from parent folder',
      label: '',
      description: '',
      type: FieldType.CHECKBOX,
      required: false,
      value: false,
      disabled: true,
      hidden: false,
    };

    /* Enables public document feature. */
    const isPublicField = {
      id: 'isPublic',
      name: 'Make document public and allow everyone to view',
      description: 'Please ensure the parent folder is public and you have admin access',
      type: FieldType.CHECKBOX,
      required: true,
      value: false,
      hidden: false,
    };

    const isSharedField = {
      id: 'isShared',
      name: 'Enable multi-folder visibility for the document',
      type: FieldType.CHECKBOX,
      required: true,
      value: false,
      hidden: false,
    };
    // For transclude document "choose template" and "isPublic" fields are not applicable
    isPublicField.hidden = this.documentType === 'TRANSCLUDE';
    templateField.hidden = this.documentType === 'TRANSCLUDE';
    isSharedField.hidden = this.documentType === 'TRANSCLUDE';
    return [{
      id: 'default_',
      name: 'default_',
      fields: [
        nameField,
        descriptionField,
        treeField,
        templateField,
        documentTypeField,
        isTemplateField,
        clonePermissionField,
        isPublicField,
        isSharedField,
      ],
    }] as FieldGroupModel[];
  }
}
