/* eslint-disable max-lines */

export const getFolders = `query GetFolders($loadAll: Boolean, $accessTypes: String!, $folderName: String, $folderIds: String, $loadType: String) {
  getFolders(
  loadAll: $loadAll,
  accessTypes: $accessTypes,
  folderName: $folderName,
  folderIds: $folderIds,
  loadType: $loadType,
   ){
    createdBy
    createdOn
    folderName
    folderAlias
    footerContent
    id
    subFolders
    parentFolders
  }
}`;

export const getFolder = `query GetFolder($folderId: String!, $loadAll: Boolean) {
  getFolder(
  folderId: $folderId,
  loadAll: $loadAll
   ){
    createdBy
    createdOn
    folderName
    folderAlias
    footerContent
    id
    subFolders
    parentFolders
  }
}`;

export const getFolderDocuments = `query getFolderDocuments($folderId: String!, $pageNumber: Int = 1, $pageSize: Int = 20, $excludedDocumentTypes: String, $documentType: String, $subFolders: Boolean $title: String) {
  getFolderDocuments(
    folderId: $folderId,
    pageNumber: $pageNumber,
    pageSize: $pageSize,
    excludedDocumentTypes: $excludedDocumentTypes,
    documentType: $documentType,
    subFolders: $subFolders,
    title: $title
  ) {
    metaData {
      pageSize
      pageNumber
      totalPages
      totalItems
    }
    documents {
      id
      title
      description
      isTemplate
      documentType
      version
      created_by
      created_on
    }
  }
}`;

export const getFolderDocumentsAndMetadata = `query getFolderDocumentsAndMetadata($folderId: String!, $pageNumber: Int = 1, $pageSize: Int = 10, $excludedDocumentTypes: String, $documentType: String) {
  getFolderMetadata(folderId: $folderId) {
    createdBy
    createdOn
    folderName
    canWrite
    canRead
    isAdmin
    isPublic
    parentId
  }
  getFolderDocuments(folderId: $folderId, pageNumber: $pageNumber, pageSize: $pageSize, excludedDocumentTypes: $excludedDocumentTypes, documentType: $documentType) {
    documents {
      version
      id
      title
      description
      documentType
      isTemplate
    }
    metaData {
      pageNumber
      pageSize
      totalItems
      totalPages
    }
  }
}`;

export const getDocuments = `query getDocuments($searchParams: String!) {
  getDocuments(
    searchParams: $searchParams,
  ) {
    metaData {
      pageSize
      pageNumber
      totalPages
      totalItems
    }
    documents {
      id
      title
      description
      isTemplate
      documentType
    }
  }
}`;

export const getDocumentsByOwnerAlias = `query getDocumentsByOwnerAlias($ownerAlias: String!) {
  getDocumentsByOwnerAlias(ownerAlias: $ownerAlias)
}`;

export const getDocumentsByOwnerAliasPreMigration = `query getDocumentsByOwnerAliasPreMigration($ownerAlias: String!) {
  getDocumentsByOwnerAliasPreMigration(ownerAlias: $ownerAlias)
}`;

export const getRoles = `query getRoles($entityId: String!, $entityType: EntityType!) {
  getRoles(
    entityId: $entityId,
    entityType: $entityType,
  ) {
    id
    roleName
    actions
    users {
    userAlias
    inherit
    inherited
    }
  }
}`;

export const getEntityRoles = `query getEntityRoles($entityId: String!, $entityType: EntityType!) {
  getEntityRolesNew(
    entityId: $entityId,
    entityType: $entityType,
  )
}`;

export const getEntityPermissions = `query getEntityPermissions($entityId: String!, $entityType: EntityType!) {
  getEntityPermissionsNew(
    entityId: $entityId,
    entityType: $entityType,
  )
}`;

export const getUserAllowedActions = `query getUserAllowedActions($entityId: String!, $entityType: EntityType!) {
  getUserAllowedActions(
    entityId: $entityId,
    entityType: $entityType,
  )
}`;

export const getRelatedDocuments = `query getRelatedDocuments($sourceDocId: String!, $relationship: String) {
  getRelatedDocuments(
    sourceDocId: $sourceDocId,
    relationship: $relationship
    ) {
      id
      title
      description
      isTemplate
      documentType
    }
}`;

export const getSupplementalSections = `query getSupplementalSections($documentId: String!, $instanceType: String!, $supplementalTag: String! ) {
  getSupplementalSections(
    documentId: $documentId,
    instanceType: $instanceType,
    supplementalTag: $supplementalTag
    )
}`;

export const getDocumentQuery = `query getDocumentQuery($documentId: String!, $instanceType: InstanceType!, $supplementalTag: String, $supplementalSectionView: Boolean, $translationTag: String) {
  getDocument(
    documentId: $documentId,
    instanceType: $instanceType,
    supplementalTag: $supplementalTag,
    supplementalSectionView: $supplementalSectionView,
    translationTag: $translationTag
    ) {
      id
      label
      description
      title
      version
      childOrdering
      subSection
      metaData
      documentType
      isTemplate
      isPublic
      revisionComments
      lastModifiedOn
      lastModifiedBy
      lastPublishedDate
      retractedComment
      retractedDate
      retractedBy
      expiration
      intakeForm
      extensionForm
      closureForm
      exceptionEnabled
      exceptionState
      supplementalMetadata
      referencedVersion
      mainTitle
      mainDescription
    }
}`;

export const getDocumentMetadata = `query getDocumentQuery($documentId: String!, $instanceType: InstanceType!, $translationTag: String) {
  getDocument(
    documentId: $documentId,
    instanceType: $instanceType
    translationTag: $translationTag
    ) {
      metaData
    }
}`;

export const getDocumentAttachments = `query getDocumentAttachments($documentId: String!, $instanceType:String!, $attachmentType:String!) {
  getDocumentAttachments(
    documentId: $documentId,
    instanceType: $instanceType,
    attachmentType: $attachmentType
  ) {
    id
    fileName
    fileId
    description
    createdBy
    createdOn
    isPublic
    attachmentType
    malwareStatus
  }
}
`;

export const getReadAttachmentUrl = `query getReadAttachmentUrl($documentId: ID!, $fileId: String!, $instanceType: String!) {
  getReadAttachmentUrl(documentId: $documentId, fileId: $fileId, instanceType: $instanceType) {
    url
  }
}`;

export const getActionItems = `query getActionItems($exceptionId: String!) {
  getActionItems(exceptionId: $exceptionId)
}`;

export const getAttachmentSignedUrl = `query getAttachmentSignedUrl($attachmentId: String!, $attachmentName: String!) {
  getAttachmentSignedUrl(attachmentId: $attachmentId, attachmentName: $attachmentName) {
    url
    fileId
  }
}`;


export const getAttributes = `query getAttributes($documentId: String!, $instanceType: InstanceType!, $pageNumber: Int, $pageSize: Int) {
  getAttributes(
    documentId: $documentId,
    instanceType: $instanceType,
    pageNumber: $pageNumber,
    pageSize: $pageSize,
  )
  {
    metaData {
      pageSize
      pageNumber
      totalPages
      totalItems
    }
    attributes {
      id
      name
      description
      type
      value
      attributeConfig
      required
      denyUpdateFieldProperties
      denyUpdateFieldResponse
      isPublicResponse
    }
  }
}`;

export const getFolderMetadata = `query getFolderMetadata($folderId: String!) {
  getFolderMetadata(folderId: $folderId) {
    createdBy
    createdOn
    folderAlias
    folderName
    footerContent
    id
    parentId
    canRead
    canWrite
    isAdmin
    isPublic
    isRelated
    parentFolders
  }
}`
  ;

export const prefixSearchDocumentsQuery = `query getDocuments($searchParams: DocumentSearchParams!) {
  getDocuments(
    searchParams: $searchParams,
  ) {
    metaData {
      pageSize
      pageNumber
      totalPages
      totalItems
    }
    documents {
      id
      label
      description
      title
      version
      childOrdering
      subSection
      metaData
      documentType
    }
  }
}`;

export const searchEmployeesQuery = `query SearchEmployees($prefix: String){
  searchEmployees(prefix: $prefix) {
    searchEmployeeOutputList {
      alias,
      personId,
      firstName,
      lastName,
      active,
    }
  }
}`;

export const searchGroupsQuery = `query searchGroups($prefix: String!, $type: String!, $limit: Int){
  searchGroups(prefix: $prefix, type: $type, limit: $limit) {
    groupInfo {
      name,
    }
    group_type
  }
}`;

export const getDocumentVersions = `query getDocumentVersions($documentId: String!){
  getDocumentVersions(documentId: $documentId) {
    name
    lastModified
    revisionComments
  }
}
`;

export const getDocumentVersionById = `query getDocumentVersionById($documentId: String!, $versionId: String!, $supplementalTag: String, $translationTag: String) {
  getDocumentVersionById(
    documentId: $documentId,
    versionId: $versionId,
    supplementalTag: $supplementalTag,
    translationTag: $translationTag)
    {
      id
      label
      description
      title
      version
      childOrdering
      subSection
      documentType
      attachments
      attributes
      supplementalMetadata
      metaData
      mainTitle
      mainDescription
    }
}`;

export const getWorkflowTemplates = `query getWorkflowTemplates($templateId: String, $versionId: String, $eventType: String, $entityId: String, $name: String, $templateStatus: String, $formId: String, $includeParentFolders: Boolean=false, $includeInherited: Boolean=false, $translationTag: String) {
  getWorkflowTemplates(
    templateId: $templateId,
    versionId: $versionId,
    eventType: $eventType,
    entityId: $entityId,
    name: $name,
    templateStatus: $templateStatus,
    formId: $formId,
    includeParentFolders: $includeParentFolders,
    includeInherited: $includeInherited,
    translationTag: $translationTag
  )
}
`;

export const getWorkflowInstances = `query getWorkflowInstances($entityId: String, $userIsOwner: Boolean=false, $userIsActor: Boolean=false, $transcludeId: String, $status: String, $sort: String, $pageNo: Int, $pageSize: Int) {
  getWorkflowInstances(
    entityId: $entityId,
    userIsOwner: $userIsOwner,
    userIsActor: $userIsActor,
    transcludeId: $transcludeId,
    status: $status,
    sort: $sort,
    pageNo: $pageNo,
    pageSize: $pageSize
  )
}`;

export const getWorkflowInstanceActions = `query getAllowedActions($workflowId: String){
  getAllowedActions(workflowId: $workflowId) {
    action
    actionText
    actorType
  }
}`;

export const getAllSubscriptions = `query getAllSubscriptions($documentId_eventType: String!) {
  getAllSubscriptions(documentId_eventType: $documentId_eventType)
}`;

export const isSubscribed = `query isSubscribed($documentId_eventType: String!, $subscriberAlias: String!) {
  isSubscribed(
    documentId_eventType: $documentId_eventType,
    subscriberAlias: $subscriberAlias
  )
}`;

export const getAcknowledgementStatus = `query getAcknowledgementStatus($documentId: String!) {
  getAcknowledgementStatus(documentId: $documentId)
}`;

export const getExceptionMetadata = `query getExceptionMetadata($documentId: String!) {
  getExceptionMetadata(documentId: $documentId)
}`;

export const getComments = `query getComments($documentId: String!, $instanceType: InstanceType!, $revisionId: Int, $replies: Boolean, $parentCommentId: String, $lastEvalKey: String, $documentSecondaryId: String) {
  getComments(
    documentId: $documentId,
    instanceType: $instanceType,
    revisionId: $revisionId,
    replies: $replies,
    parentCommentId: $parentCommentId,
    lastEvalKey: $lastEvalKey,
    documentSecondaryId: $documentSecondaryId
  ){
    comments {
      addedBy
      createdAt
      updatedAt
      content
      commentId
      isParent
      revisionId
      instanceType
    }
    lastEvalKey
  }
} `;

export const getAuditLogs = `query getAuditLogs($docId: String!, $lastEvalKey: String) {
  getAuditLogs(
    docId: $docId,
    lastEvalKey: $lastEvalKey
  )
} `;

export const getTags = `query getTags($documentId: String!) {
  getTags(documentId: $documentId)
}`;

export const getAllTags = `query getAllTags($prefix: String!) {
  getAllTags(prefix: $prefix)
}`;

/**
 * Gets an array of all acknowledgement processes for the current document.
 */
export const getAcknowledgements = `query getAcknowledgements($documentId: String, $pageSize: Int, $pageNo: Int) {
  getAcknowledgements(documentId: $documentId, pageSize: $pageSize, pageNo: $pageNo)
}`;

/**
 * Gets an array of all pending acknowledgement requests for the current user. If the optional documentId and
 * documentVersion are passed in, it returns acknowledgement requests for only that specific document.
 */
export const getPendingAcknowledgements = `query getPendingAcknowledgements($documentId: String, $documentVersion: String, $pageSize: Int, $pageNo: Int, $status: String) {
  getPendingAcknowledgements(documentId: $documentId, documentVersion: $documentVersion, pageSize: $pageSize, pageNo: $pageNo, status: $status)
}`;

/**
 * Gets an array of all acknowledgement requests made on a document.
 */
export const getAcknowledgementMetrics = `query getAcknowledgementMetrics($documentId: String, $pageSize: Int, $pageNo: Int, $status: String, $lastEvaluatedId: String, $lastEvaluatedUserAlias: String) {
  getAcknowledgementMetrics(documentId: $documentId, pageSize: $pageSize, pageNo: $pageNo, status: $status, lastEvaluatedId: $lastEvaluatedId, lastEvaluatedUserAlias: $lastEvaluatedUserAlias)
}`;

// Given two versions of a document, return the differences.
export const comparison = `query comparison($documentId: String!, $leftRevisionId: String!, $rightRevisionId: String!, $leftSupplementalTag: String, $rightSupplementalTag: String, $leftTranslationTag: String, $rightTranslationTag: String, $dependentDocId: String) {
  comparison(documentId: $documentId, leftRevisionId: $leftRevisionId, rightRevisionId: $rightRevisionId, leftSupplementalTag: $leftSupplementalTag, rightSupplementalTag: $rightSupplementalTag, leftTranslationTag: $leftTranslationTag, rightTranslationTag: $rightTranslationTag, dependentDocId: $dependentDocId)
}`;

/**
 * Gets an array of all acknowledgement requests made on a document.
 */
export const getExceptionExclusions = `query getExceptionExclusions($documentId: String!) {
  getExceptionExclusions(documentId: $documentId)
}`;

export const getExceptionRequestFormsMetadata = `query getExceptionRequestFormsMetadata($documentType: String!, $documentId: String!) {
  getExceptionRequestFormsMetadata(documentType: $documentType, documentId: $documentId)
}`;

export const getTemplateWorkflow = `query getTemplateWorkflow($entityId: String!, $formId: String, $templateId: String) {
  getTemplateWorkflow(entityId: $entityId, formId: $formId, templateId: $templateId)
}`;

export const getReviewSchedule = `query getReviewSchedule($entityId: String!, $entityType: String!) {
  getReviewSchedule(entityId: $entityId, entityType: $entityType)
}`;

export const getFavorites = `query getFavorites {
  getFavorites
}`;

export const getPreferences = `query getPreferences {
  getPreferences
}`;

export const getUserGroups = `
  query getUserGroup {
    getUserGroup
  }
`;
export const getExceptionTemplate = `query getExceptionTemplate($entityId: String!, $allData: Boolean = true) {
  getExceptionTemplate(entityId: $entityId, allData: $allData)
}`;

export const getExceptionResponseInstance = `query getExceptionResponseInstance($entityId: String!) {
  getExceptionResponseInstance(entityId: $entityId)
}`;

export const getRiskMatrices = `query getRiskMatrices($entityId: String!, $includeParentFolders: Boolean = false) {
  getRiskMatrices(entityId: $entityId, includeParentFolders: $includeParentFolders)
}`;

export const getRiskMatrix = `query getRiskMatrix($matrixId: String!) {
  getRiskMatrix(matrixId: $matrixId)
}`;

export const getRisks = `query getRisks($entityId: String!) {
  getRisks(entityId: $entityId)
}`;

export const getAllowedActionsForScope = `query getAllowedActionsForScope($workflowInstanceId: String!, $actionsScope: String!) {
  getAllowedActionsForScope(workflowInstanceId: $workflowInstanceId, actionsScope: $actionsScope)
}`;

export const getRiskRankTemplate = `query getRiskRankTemplate($templateId: String!) {
  getRiskRankTemplate(templateId: $templateId)
}`;

export const getRiskRankForm = `query getRiskRankForm($formId: String!) {
  getRiskRankForm(formId: $formId)
}`;

export const getDocumentView = `
  query getDocumentView($entityId: String!) {
    getDocumentView(entityId: $entityId)
  }
`;

export const getSecondaryParents = `
  query getSecondaryParents($documentId: String!) {
    getSecondaryParents(documentId: $documentId)
  }
`;
export const getDocumentMetadataRS = `
  query getDocumentMetadataRS($clientId: String!, $entityType: String!, $entityId: String!) {
    getDocumentMetadataRS(clientId: $clientId, entityType: $entityType, entityId: $entityId)
  }
`;

export const getBatchActionsStatus = `
  query getBatchActionsStatus($batchId: String){
    getBatchActionsStatus(batchId: $batchId)
  }
`;

export const getExportDocumentHistory = `
  query getExportDocumentHistory($entityId: String!){
    getExportDocumentHistory(entityId: $entityId)
  }
`;

export const getDependentDocuments = `
  query getDependentDocuments($transcludeId: String!){
    getDependentDocuments(transcludeId: $transcludeId)
  }
`;

export const getLanguages = `
  query getLanguages($documentType: String){
    getLanguages(documentType: $documentType)
  }
`;
